body{
    background-color: white;
    margin:0;
    overflow: hidden;
    
  
}
body.resizing *{
    transition: 0ms !important;
}
/* #root{
display: flex;
justify-content: center;
}
.App{
    max-width: 3000px;
    width: 100vw;
} */
html{
    scrollbar-color: var(--secondary-dark) transparent;
    overflow-x: hidden;
}
.header-toggled-global svg > path{
    transition: stroke 500ms ease 0.2s, clip-path 500ms ease !important;
    stroke: black !important;
}
.header-toggled-global div.header-logo{
    transition: background-color 500ms ease 0.2s !important;
    background-color: black !important;
}
div.header-logo{
    transition: background-color 500ms ease 1.1s !important; 
}
body:not(:has(.loading-wrap.is-loading)){
    background-color: white !important;
}
.page-transitioning .large-arrow-cursor, body:has(.loading-wrap.is-complete) .App > .large-arrow-cursor, .loading-wrap.is-loading .large-arrow-cursor {
    z-index: 9999 !important;
}

.page-transitioning .arrow-cursor-image, body:has(.loading-wrap.is-complete) .App > .large-arrow-cursor >  .arrow-cursor-image, .loading-wrap.is-loading .arrow-cursor-image {
    transition: all 0.2s ease-out, transform 100s linear, filter 200s 0s !important;
    transform: translateY(-100%) !important;
    scale: 1 !important;
    filter: 0;
}
.page-content{
    background-color: white;

}

.page-transitioning *{
    cursor: none !important;
}
.page-transitioning .App > div *{
    pointer-events: none !important;
}
body:has(.loading-wrap.is-loading) .large-arrow-cursor, .page-transitioning .large-arrow-cursor{
    scale:1 !important;
}
body:has(.loading-wrap.is-complete) .mask-image-wrap{
    clip-path: inset(100% 0 0 0 round 40px);
    transform: scale(0.992);
}
body:has(.loading-wrap.is-complete) .mask-image-wrap > div:first-child{
    transform: scale(2) !important;
}
body:has(.loading-wrap.is-loading) .mask-image-wrap > div:first-child{
    pointer-events: none;
}

html:has(.header-toggled-global){
    overflow: hidden !important;
}

.pin-spacer{
    pointer-events: none !important;
}
.high-z-index-layer{
    position: relative;
    z-index: 11;
}
body:has(.header-toggled-global){
    overflow: hidden;
}
.scroll-container::-webkit-scrollbar {
    display: none !important;
  }
  footer{
    z-index: 100;
    position: relative;
  }
.scroll-container{
    overflow-y: scroll !important;

    /* //Site Background color */



    position: fixed;
    top: 0;
    width: 100vw;
    height:100vh;
}
body:has(.reference-peace-first-page:hover) .large-arrow-cursor{

    transform: translateY(-100%) translateX(-100%) !important;
    scale:0.61;
    width: 85px;
    transition: transform 500ms ease, max-height 500ms ease, max-width 500ms ease, margin 500ms ease, filter 300ms ease 0ms, scale 300ms ease 0s !important;
}
.large-arrow-cursor{

    position: absolute;
    height:84px;
    width:84px;
  

    z-index: 10;
    pointer-events: none;
    transform-origin: top left;
    transition: transform 500ms ease, max-height 500ms ease, max-width 500ms ease, margin 500ms ease, filter 300ms ease 0ms, scale 300ms ease;
    overflow: hidden;
    background-repeat: repeat-y;
}
body:has(header .header-menu-link:hover) .large-arrow-cursor{
transition: transform 500ms ease, max-height 500ms ease, max-width 500ms ease, margin 500ms ease, filter 500ms ease 0ms;
}
.header-logo{
    cursor: pointer !important;
}
body:has(.header-menu-subtext-links:hover) .large-arrow-cursor, body:has(.header-toggled-global .header-inner-content:hover) .large-arrow-cursor, body:has(.header-hamburger:hover) .large-arrow-cursor,  body:has(.header-logo:hover) .large-arrow-cursor{
    max-height: 0px !important;
    max-width: 0px !important;
}
.arrow-cursor-image{
    position: absolute;
    top:0;
    content: '';
    height:calc(84px * 200);
    width:85px;
    background: white;
    mask-repeat: repeat-y;
    mask-image: url(/public/Arrow/White-Arrow-Large.svg);
    mask-size: contain;
    z-index: 10;
    pointer-events: none;
    transition: all 0s ease-out;
    display: block;
  
}

.transition-overlay{
    transition: 0ms cubic-bezier(0.76, 0, 0.24, 1), background-color 500ms ease ;
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: hidden;
    top:100%;
    background-color: var(--secondary-dark);
    border-radius: 20px;
    padding-block:20px;
    z-index: 9000;
    display: flex;
    justify-content: center ;
    align-items: center;
}

.page-transitioning .transition-overlay{
    transition: 1100ms cubic-bezier(0.76, 0, 0.24, 1), background-color 500ms ease ;
}

.scrolled .project-scroll-height{
    max-height: 100% !important;
}
.page-transitioning .header-minimal-background{
    transition-delay: 50000ms !important;
}
header:has(.header-menu-link:hover) .link-desc{
    opacity: 1 !important;
}
.transition-overlay{
    transition-delay: 1000ms;
    cursor: none;
}
.page-transitioning .transition-overlay{
    transition-delay: 400ms;
    top:-20px;
}
.mask-mouse-area > a{
    height:auto;
}
.page-transitioning .mask-image-wrap{
    z-index: 9999 !important;
}

.hovered-project h2{
    transform: translateX(0.2em);
}
body:has(.contact-page) .header-logo{
    filter: drop-shadow(1.5px 1.5px 0 white) 
    drop-shadow(-1px -0px 0 white);
}
body:has(.contact-page) .header-inner-content{
    padding-top: 22px;
    height: 120px;
}
.header-inner-content{
    transition: all ease 700ms 100ms;
    z-index: 8000;
}
body:has(.home) .header-inner-content{
    padding-top: 22px;
    padding-bottom: 22px;
    height: 120px;
}
body:has( .login) .large-arrow-cursor{
    transform: scale(0) !important;
}
.scrolled .header-inner-content{
    height:40px !important;
    padding-block: 22px !important;

}
footer a.primary-button{
    color: #181818 !important;
}
.film-project-item .primary-button::after{
    transition: 500ms ease all !important;
    margin-top:0.15em;
}
.film-project-item .primary-button{
    padding-right: 38px !important;
    height: fit-content;
    line-height: 0.9em;
}
.scrolled .contact-max-title{
    opacity: 0;
}
.scrolled .header-wrap{
    transition:400ms ease-in-out all 0.5s;
    min-height:84px;
}
body:has(.home) .header-hero-cta{
    display: block;
}
.scrolled .header-hero-cta{
    transition-delay: 0ms;
    opacity: 0;
}
body:has(.home) .header-logo{
    filter: drop-shadow(1.5px 1.5px 0 white) 
    drop-shadow(-1px -0px 0 white);
}
body:has(.home) .header-logo-link{


    transform: scale(2.8) translate(-2px, 5px) rotate(-5deg);

 

}
.page-transitioning .header-logo-link{
    transform: scale(1) translate(0) rotate(0) !important;
}

body:has(.contact-page) .header-logo-link{


    transform: scale(2.8) translate(-2px, 5px) rotate(-5deg);

    transition: all ease-out 700ms;

 

}

.scrolled:has(.home) .header-logo-link, .scrolled:has(.contact-page) .header-logo-link{
    transform: scale(0.8) translate(0) rotate(0);


}
.header-toggled-global *{
    cursor:none;
}
body:has(.header-toggled-global) .large-arrow-cursor{
    z-index: 9998;
    scale:1;
    transform: translate(-50%, -50%) !important;
}
body:has(.header-menu-link:hover) .large-arrow-cursor{
    transform-origin: top left;
    transform: scale(0.2) !important;
    margin-top:-48px;
    margin-left:-38px;
}
body:has(.page-three-wrap:hover) .large-arrow-cursor{
    max-height: 0px !important;
    max-width: 0px !important;
    transition: transform 500ms ease, max-height 100ms ease, max-width 100ms ease, margin 500ms ease, filter 300ms ease 0ms, scale 300ms ease;
 
}
body:has(header .header-menu-link:nth-child(5):hover) .large-arrow-cursor{

    transition: transform 500ms ease, max-height 500ms ease, max-width 500ms ease, margin 500ms ease, filter 500ms ease 250ms !important;
    filter: invert(1);

}
body:has(.header-menu-link:hover) header > .header-hover-desc{
    transition: transform 500ms ease, width 500ms ease, max-height 500ms ease, max-width 500ms ease, padding 500ms ease 0.5s, clip-path 500ms ease 0.15s, margin 400ms ease !important;
    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%) !important;
}
body:has(.header-menu-link:hover) header > .header-hover-desc > p  > span{
    transition: clip-path 500ms ease-out 0.4s, filter 500ms ease 0ms !important;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%) !important;
}
.scrolled .header-logo{
    transition-delay: 0;
    transition: all 100ms ease;
    filter: drop-shadow(1px 0.5px 0px white)     drop-shadow(-0.5px 1px 0px white) drop-shadow(0.2px -0.2px 0px white) !important ;
}
.scrolled .header-hamburger svg{
filter: drop-shadow(1px 0.5px 0px white)     drop-shadow(-0.5px 1px 0px white) drop-shadow(0.2px -0.2px 0px white) !important ;

}
body:has(header .header-menu-link:nth-child(5):hover) .large-arrow-cursor{

    z-index: 8000 !important;

}

.header-logo-link{
    transform-origin: center left;

    transition: ease-out all 750ms;
}
.text-reveal-container{
    width: 100%  !important;
}
body:not(.page-transitioning):has( .masonry-section:hover) .large-arrow-cursor, body:not(.page-transitioning):has(.random-masonry-image-view:hover) .large-arrow-cursor{

    transform:translate(-50%, -50%) scale(0.6) !important;
  
    
}
.header-logo-link{
    transition-delay: 0ms !important;
}
body:has(.mask-image:hover) .header-hamburger{
    cursor: none;
}
.scrolled .infinite-film-project-item.hovered h2{
   
    transform: translateX(0.3em);
}
.scrolled .header-hamburger{
      pointer-events: all;
      
      transition-delay: 100ms;
    
    transform: scale(1.1);
    transform-origin: top right;
}
.header-hamburger{
    transition: filter 500ms ease 1s !important;
}
.header-hamburger path{
    transition: stroke 500ms ease 1s, clip-path 500ms ease !important;
}
.header-toggled-global .header-hamburger{
    transition: filter 500ms ease 0.2s !important;
}
.white-char{
    color:white;
}
.second-slide-projects{
    position: absolute;
    left: 100%;
    top:0;

}
.press-down .home-hover-desc{
    scale: 1.02 !important;
}
.page-transitioning .project-color{
    transition: transform 2000ms ease !important;
    transform: translateY(500%) translateX(-0.2em) !important;
}

.page-transitioning .text-reveal-element.title{
    transition: transform 3000ms ease !important;
    transform: translateY(100%) !important;
}
.page-transitioning .header-hover-desc{
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%) !important;
}
footer .primary-button::after{
    background-color: #181818 !important;
}
footer .primary-button::before{
    display: none;
}
.scrolled .header-toggled-global .header-inner-content {
    height:120px !important;
}
body:has(footer.visible):not(:has(.header-toggled-global)) header > div > div > .header-inner-content{
    transition: 500ms ease transform !important;
    transform: translateY(-100%) !important;
}
body:has(footer.visible) .projects-image-wrap{
    transition: 500ms ease-out clip-path;
    clip-path: inset(0 0 100% 0 round 10px);
}
.home:has( .page-two.visible) .dynamic-video-player-1 .mask-image-wrap .player-project-name p span{
    transition: ease-out 1s all 0s, opacity 0.5s ease;
    opacity: 1;
    transform: translateY(0%);   
}
body:has(.all-projects-page-wrap) .large-arrow-cursor{
    width: 0;
    height: 0;
}

.film-page-title-wrap{
    transition: 500ms ease opacity;
}
.scrolled .film-page-title-wrap{
    transition-delay: 0ms;
    opacity: 0;
}
body:has(.film-page-wrap.fast-active) .header-wrap {
    transition: 0ms !important;
    min-height: 0 !important;
    background-color: transparent !important;
}
.scrolled:has(.film-page-wrap.fast-active) .floating-project-thumb-wrap > div{
    transition: 1000ms cubic-bezier(0.76, 0, 0.24, 1) ;
    height: 22vw;
}
body:has(.header-toggled-global) .large-arrow-cursor, .page-transitioning .App > .large-arrow-cursor, body:has(.loading-wrap.is-loading) .large-arrow-cursor{
    height: 84px;
    width: 84px;
}



.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}

@keyframes spin {
    from   { }
    to { transform: rotate(360); }
  }



  @media only screen and (max-width: 830px) {
    .scrolled .header-logo-link{
        transform: scale(0.8) translate(0) rotate(0);
    }
    body:not(.scrolled):has(.home) .header-inner-content{
        height: 10.3vw;
        padding-top: calc(4vw + 40px);
    }
    .title, .heading{
        font-size: 13.5vw !important;
        line-height: 1.1em !important;
    }
    .large-arrow-cursor{
        display: none;
    }
    .header-toggled-global .header-inner-content{
        filter: invert(1) !important;
    }
  }





  .fadeIn {
    animation: 0.5s fadeIn forwards;
  }
  
  .fadeOut {
    animation: 0.5s fadeOut forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(-20px, 0);
    }
    to {
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translate(0px, 0px);
    }
    to {
      transform: translate(-20px, 0);
      opacity: 0;
    }
  }