.footer{

    background-color: white;
    width:100%;
    height:120px;
    
}
.footer a{
    color:#181818
}
.footer-wrap{
    width:92vw;
    margin:auto;
    margin-inline: 4vw;
    display: flex;
    justify-content: space-between;

    gap:1.3%;
    align-items: center;
    
    height:100%;

}
.footer-wrap > div{
    width:24%;

}
.footer-col-1{
    display: flex;
}
.footer-col-1 > div{
    width: 8vw;
}
.flag-wrap{
    display: flex;
    gap:1rem;
}
.flag{
    opacity: 0.5;
    transition: 500ms ease all;
}
.flag:hover{
    opacity: 1;
    
}
.footer-wrap > div:first-child{
    width:76%
}
.footer-col-2, .footer-col-3, .footer-col-4{
    display: flex;
    flex-direction: column;
    transform:translateY(4px);
    position: relative;
}
.footer-col-2 a, .footer-col-3 a, .footer-col-4 a{
    cursor:pointer;
}
a{
    font-family: var(--font-family);
    font-weight:500;
    font-size: 16px;
}
@media only screen and (max-width:830px){
    .footer{
        height: fit-content;
    }
    .footer-wrap{
        width: 86%;
        margin-inline:6.5%;



            display: flex;
            flex-direction: column;
            min-height: 220px;
            height: fit-content;
            padding-top:20px;
            

    }

    .footer-col-1{ grid-area: 1 / 1 / 2 / 2; }
    .footer-col-2 { grid-area: 1 / 2 / 2 / 3; }
    .footer-col-3 { grid-area: 1 / 3 / 2 / 4; }
    .footer-col-4 { grid-area: 2 / 1 / 3 / 4; }

    .footer-col-1 > div{
        width: fit-content;
    
    }
    .all-button, .back-to-top{
        display: none !important;
    }

    .footer-wrap > div{
        width:100% !important;
        flex-direction: column; align-items: center;
        margin-left:0px;
    }
    .footer-wrap > div a{
        padding-bottom: 0.5em;
    }
    .footer-col-1{
        padding-bottom: 2em;
    }
    .footer-col-2 {
        margin-left:20px;
    }
    .footer-col-4 > a:nth-child(2){
        padding-top: 4vw;
    }
    .footer-col-4 > a{
        text-align: center;

 
    }
    .footer-col-4{
        padding-bottom: 8vw;
    }
}