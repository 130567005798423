.loading-wrap{
    position: fixed;
    width: 100%;
    height: 100%;
    padding-block: 20px;
    background-color: var(--secondary-dark);
    top:-20px;
    z-index: 9998;
    border-radius: 20px 20px 20px 20px ;
    transition-timing-function: cubic-bezier(0.76, 0, 0.24, 1) !important;
    clip-path: inset(0 0 0 0 round 20px);
}
@media only screen and (max-width: 830px) {
    .loading-wrap{
        width: 100vw;
        height: 100vh;
    }
}