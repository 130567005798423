.project-title{
    line-height: 120px;
    margin:0px;
    margin-bottom: -10px;
    

}
.project-page *{
    color:var(--primary-color);
}
.project-page section{
    width:92vw;



    margin-inline: 4%;

    background-color: white;
}
.main-video-controls-overlay .scroll-notification{
    transition-delay: 0ms !important;
    opacity: 1 !important;
}

.project-page-section-1{
    display: flex;
    align-items: end;
    height: 100vh;
    margin-bottom: calc(150vh);
}
.main-section-details{
    margin-bottom: 4vw;
}
.main-section-image-wrap{
    position: absolute;
    top: 0;
    height: calc(100vh - 4vw - 84px);
    display: flex;
    pointer-events: none;
    justify-content: end;
    align-items: end;

    width:92vw;
    margin-inline: 4vw;
    margin-top:84px;
    margin-bottom:4vw;

}
.main-section-image{
    pointer-events: all;
    width: 49%;
    border-radius: 20px;
    overflow: hidden;
    height: calc(100vh - 8vw);
    position: relative;
}
.main-section-image-overlay{
    width: 100%;
    opacity: 1;
    height: 100%;
    background: #EEEEEE;
    background-size: cover;
    position: absolute;
    background-position: center;
    z-index: 10;
    pointer-events: none;
    transition: all 500ms ease;
}
.main-section-image:has(.playing) .main-section-image-overlay{
    opacity: 0;
}
.project-page-section-1 > div{
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 50%;

}
.main-description-wrap{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;


}

.main-description-wrap-left{
    width: 29.7vw !important;
    gap: 1.3vw;

    display: flex;
    flex-direction: row;
}

.main-description-wrap-right{
    width:15vw !important;
    display: flex;

    align-items: end;
    cursor: pointer;

}
.main-description-wrap-right>span{
    width: fit-content !important;
}
.main-description-wrap-right:hover > .primary-button{
    width: fit-content !important;
}
.main-description-wrap-left>span{

    width: 14.2vw !important;

}
.main-description-right-wrap{
    display: flex;
    flex-direction: column;
    cursor: pointer;
    
}

.main-description-wrap>div{
  width: 100%;
}
.main-video-controls-overlay{
    position: absolute;
    display: flex;
    width: 92vw;
    margin-inline: 4vw;

    height: calc(100% - 4vw);
    top:0;
    z-index: 100;
    display: flex;
    align-items: end;
    pointer-events: none;
}
.main-description-time-wrap{
    opacity: 0;
    margin-bottom: 46px;
    color: white;
    margin-left: 64px;
    width: calc(34% - 64px);
    transition: 500ms ease all 0ms;
}

.main-video-controls-overlay > div{
    pointer-events: all;
}
.scroll-notification *{
    z-index: 10;
    position: relative;
    color: white;
    opacity: 1;
}
.scroll-notification:hover{
    opacity: 1;
}
.details-section-image-wrap{
    border-radius: 20px;
    width: 49.2%;
    height: calc(100vh - 90px);
    overflow: hidden;
}

.directed-subtext{
    margin-bottom: 10vh;
    margin-top: 0 !important;
}
.masonry-image{
    cursor: none;
    width:100% !important;
    scale:1.03;
    transition: all 0.2s ease;
    object-fit: center center;
}

.masonry-image:hover{
    scale:1.02;
}
.masonry-section>div{
    margin-top:1.4vw !important;
}
.masonry-section{

    cursor: none !important;
}
.masonry-section *{
    cursor: none !important;
}
.parallax-image-wrap{
    overflow: hidden;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    transition: all 500ms ease;
    position: relative;
}
.masonry-image.masonry-main-image{
    position: absolute;
}
.parallax-image-wrap:hover{
    transform: scale(0.99);
}
.main-description{
    width: 100% !important;
    pointer-events: all !important;
    z-index: 20;
    position: relative;
    display: flex;
    flex-direction: column;
  
}
.details-title{
    width: 100%;
    margin-bottom: 20px !important;
    word-break: break-word;
    -moz-hyphens: manual;
    -ms-hyphens: manual;
    hyphens: manual;
}





.details-section{
    min-height: calc(100vh - 90px);

    margin-top: 1.2vw;
    margin-bottom: 1.2vw;
    display: flex;
    justify-content: space-between;
    align-items: end;
}
.details-section-image{
    background-size: cover !important;
    background-position: center center !important;
    height: calc(100vh - 90px);
    cursor: none;
    border-radius: 20px;
    width:100%;
    background: #EEEEEE;
}
.details-section-image > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}
.details-description{
    width: 14.2vw !important;
    margin:0;
}
.details-title{
    margin-bottom:48px;
}
.details-description span{
    display: flex;
    width: 100%;
}
.text-section{
    min-height: 0px;
    margin-block:4vw;
}
.text-section .details-description{
    width: 100% !important;
}
.large-section{
    margin-top: 1.2vw;
    position: relative;
    height: max-content;
    aspect-ratio: 16 / 9;
}
.large-section, .large-section img{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
}
.large-section img{
    cursor:none;
}
.title-section{
    margin-block:4vw;
    display: flex;
    justify-content: center;
}
.title-section-wrap{
    width: 80%;
    text-align: center;
}
.title-section-wrap>h3{
    width: 100% !important;
}
.details-description-wrap{
    display: flex;
    gap: 2.8%;
    width: 100%;
    margin-bottom:4vw;
}

.details-section-wrap{

    width: calc(49.2% - 4vw);

}

.gallery-page{
    width: 92vw;
    margin-inline: 4vw;
}
.video-container {
    display: flex;
    align-items: center;
    height: 100%;
    
 }
.player__wrapper{
    height: calc(100vh - 2.4vw);
    overflow: hidden;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.player {
    aspect-ratio: 9 / 16;
    cursor: none;
    height: 300%;
    min-height: 300%;
    margin: 0;
    object-fit: cover;
    padding: 0;
    scale: 1;
    min-width: 100%;
    width: auto;
    width: auto;
    display: flex;
    justify-content: center;

}
 .player div{
    pointer-events: none;
    width: 100%;
 }            
 
 .progress-bar{
    background-color: #ffffff30;
    border-radius: 20px;
    clip-path: inset(0 100% 0 0 round 20px);
    display: flex;
    align-items: end;
    position:absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 80px;
    margin-right: 64px;

    transition: 500ms ease all 300ms, clip-path ease-out 600ms;
 
    width: 15.6em;
 }
 .progress{
    background-color: white;
    height: 3px !important;
    border-radius: 1.5px;
    overflow: hidden;

    clip-path: inset(0 0 0 0 round 20px);
    transition: 500ms ease all 300ms, clip-path ease-out 800ms, width 0.5s linear;
 }
 .scroll-notification p span{
   animation: scrollText 3s infinite;
   animation-timing-function: cubic-bezier(0.76, 0, 0.24, 1);
   animation-delay:2500ms;
 }
 @keyframes scrollText {
   from   { transform: translateY(0%); }
   to {     transform: translateY(-90%);}
 }
 .details-section{
    flex-direction: row-reverse;
 }
 .details-section-wrap-reversed{
    flex-direction: row;
}
.navigation-section{
    display: flex;
    width: 92vw;
    padding-inline: 4vw;
    margin-top: 2em;
    
}

.navigation-section > div{
    flex-grow: 1;
}
.nav-button.prev a::before{
    content:'';
    background-color: var(--primary-color);
    aspect-ratio: 1;
    mask-image: url('/public/Arrow/White-Arrow-Large.svg');
    mask-size: cover;
    display: inline-block;
    height: 87%;
    margin-right: 0.2em;

}
.nav-button.next a::after{
    content:'';
    background-color: var(--primary-color);
    aspect-ratio: 1;
    mask-image: url('/public/Arrow/White-Arrow-Large.svg');
    mask-size: cover;
    display: inline-block;
    height: 87%;
    transform: scaleX(-1);
    margin-left: 0.2em;

}
.nav-button{
    display: flex;
  
}
.nav-button.next{
    justify-content: end;
}
.project-nav-indicator{
    display: flex;
    justify-content: center;
    align-items: center;
}
@media only screen and (max-width: 830px){
.large-section{
    margin-bottom: 3.2vw;
    margin-top:4vw;
}
.project-page-section-1{
    margin-bottom:0vw;
}
.main-section-image{
    width: 100% !important;
    height: 50% !important;
}
.project-page-section-1 > div{
    width: 100%;
}
.main-description-wrap-left{
    width: 100% !important;
}
.main-description-wrap-left > span{
    width: 50% !important;
}
.details-section{
    flex-direction: column;
    gap: 4vw;
}
.details-section-wrap{
    width: 100%;
}
.title-section-wrap{
    width: 100%;
    word-break: break-all;
    hyphens: manual;
}
.nav-button > a{
    font-size: 0 !important;
}
.nav-button > a::after, .nav-button > a::before{
    height: 8vw !important;
}
.navigation-section{
    width: 84vw;
    padding-inline: 8vw;
}
.details-section{
    margin-block: 4vw;
}
.details-section-image-wrap, .details-section-image-wrap > div{
    width: 100% !important;
    aspect-ratio: 1;
    height: auto !important;
}
.details-section-image-wrap img{
    aspect-ratio: 1;
    height: auto !important;
}
.details-description-wrap > div{
width: 50% !important;
}
}