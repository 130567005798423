.about-page{
    padding-top:80px;
    width: 100vw;
    overflow: hidden;
}
.about-page section{
    width:92vw;
    margin-inline:4vw;
    margin-bottom: 4vw;
    height:calc(100vh - 80px - 4vw);
    position: relative;
}

.section-1-heading{
    pointer-events: none;
    margin: 0;
}
.section-1-heading > div{
    width: max-content !important;
    pointer-events: all;
}

.section-1-content{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.my-process-title{
    position: relative;
    white-space: nowrap;
}
.section-1-content > div{
    width: 22%;
}
.scroll-notification{
    color:black;
    opacity: 1 !important;
    right:0;
    bottom:0;
    margin:0 !important;
    width: 14.5vw !important;
    pointer-events: all;
    padding-bottom: 18px;
    transition-delay: 0ms !important;
    display: flex;
    justify-content: start;
    position: relative;
}
.section-1-content > .section-1-image{
    position: absolute;
    bottom: 0;
    right:0;
    width:66.5%;
    border-radius: 20px;
    height: calc(100vh - 80px - 18vw); 
    background-color: lightgray;
    overflow: hidden;
}
.section-1-image > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
}


.about-page-slide{
    height:30vw;
    width: 100vw;
    position: relative;
    width: 100vw;
}
.about-page-slide > div{
    position: relative;
    height:100%;
    width: min-content;

}
.slide-projects{
    height: 100%;
    display: flex;
    width: min-content;
}
.slide-project{
    
    min-width: 45.2vw;
    width: 45.2vw;
    max-width: 45.2vw;
    overflow: hidden;
    height: 100%;
    border-radius: 20px;
    padding-inline: 2vw;
    margin-inline: -1px;
   
}
.slide-project-image{
    /* background-image: url(/public/domengo.png); */
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    cursor: none;
}
.testimonial-image{
    background-image: url(/public/testimonialPlaceholder.webp);
}

.slide-project{
    position: relative;
    transition: 500ms ease scale;

}
.slide-project-details{
    margin-left:64px;
    margin-bottom:56px;
    filter: invert(1);
    position: absolute;
    bottom:0;
    left:2vw;
    text-shadow: 0 0 5px #ffffff;
}
.slide-project-details p{
    display: block;
    height: 1em;
    overflow: hidden;
    margin: 0;
    display: flex;
    opacity: 0;
    transition: all 500ms ease;

}
.slide-project-details .primary-button{
    padding-bottom: 8px;
    margin-top:-0.3em;
    font-size: 18px;
    cursor: none;
}
.slide-project-details .primary-button:after{
    width: 15px;
    right:10px;
    transition: 500ms ease all 500ms !important;
    transform: translateY(24px) !important;
    background-color: black;
}
.slide-project-details p span{

    transform: translateY(100%);
    transition: 0ms 0.5s;
    
}
.slide-project.hovered p{
    opacity: 1;
}
.slide-project.hovered p::after{
    transition: 1000ms ease all 300ms !important;
    transform: translateY(0px) !important;
}
.slide-project.hovered p span{
    transition: ease-in-out 1s all 0.2s;
    transform: translateY(0%);
}
.portfolio-slide-subtext{
    width: 92vw ;
    margin-inline: 4vw;
    margin-top: 2em;
    margin-bottom: 15rem;
    display: flex;

    justify-content: space-between;
}
.portfolio-slide-subtext > div{
    width: 49%;
    height:50px;
}
.portfolio-slide-subtext-left > p{
    width: 22vw;
    margin-block:0px;
}
.portfolio-slide-subtext-right{
    height:100%;
    width: 22.2vw !important;
    display: flex;
    align-items: end;
    flex-direction: column;
    justify-content: space-between;
}
.portfolio-slide-subtext-right p{
    margin:0;
}
.about-process{
    margin-top: calc(10rem);
    height:fit-content !important;
    padding-bottom: 5rem;
}
.about-process-wrap{
    position: absolute;
    height:calc(100vh - 8vw - 80px);
    min-height:calc(100vh - 4vw - 80px);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    top:0;
    left: 0;
}
.about-process-image-wrap{

    max-height: calc(100vh - 10vw);
    height: calc(100vh - 180px - 8vw);
    width: 45.2vw;
    overflow: hidden;
    border-radius: 20px;
}
.about-process-inner-image-wrap{
    display: flex;
    flex-direction: column;
}
.about-process-image-wrap img{
    width: 100%;
    height: calc(100vh - 100px - 8vw);
    object-fit: cover;
    object-position: center center;
}
.my-process-title{

    margin-bottom: 3rem;

}
.about-process-count{
    position: absolute;
    z-index: 5;
    bottom: 0;
    filter: invert(1);
    margin: 40px;
    pointer-events: none;
    display: inline-block;
    overflow: hidden;
    height: 1em;
    padding-top:0px;


}
.about-process-image-wrap .about-process-index{

    margin: 0;
    display: inline-flex;
    flex-direction: column;
    max-height: 7.2vw;
    line-height: 1em;
    margin-top: -1vw;
   
    overflow: hidden;
}

.about-last-cta-wrap-heading img{
    transform: scale(2.8) rotate(-5deg);
    position: absolute;
    top:36%;
    left:0.2%;
    filter: drop-shadow(1.5px 1.5px 0 white) drop-shadow(-1px -0px 0 white);
}
.about-process-slides{
    margin-left: 54.4vw;
    height:240vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.about-process-slide-1 .about-process-slide-title{
    margin-top: 8rem;
}
.about-process-slide-2 .about-process-slide-title{
    margin-top: 2rem;
}
.about-process-slide{
    display: flex;
    flex-direction: column;
    height: 60vh;
}
.about-process-slide-text{
    margin-top:2em;
    width:22vw;
}
.testimonial-slide-subtext{
    width: 92vw;
    margin-inline:4vw;
    margin-top: 10rem;
    margin-bottom:5rem;
    display: flex;
}
.testimonial-subtext-col-1{
    width: 15.5vw;
}
.testimonial-subtext-col-2{
    width: 62vw;
}
.about-page-section-3{
    margin-top: 5rem;
    height: auto !important;
}
.testimonial-subtext-col-1{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.section-1-image, .about-process-image-wrap{
    pointer-events: all;
    cursor: none;
}
.section-1-text, .section-1-text > p{
    width: 14.5vw;
}

.section-1-heading{
    width: 100% !important;
}
.section-1-heading h2{
    margin: 0;
}
.about-last-cta-wrap-heading{
    font-size:64px;
    position: relative;
    width: fit-content;
    cursor: pointer;
}
.about-last-cta-wrap{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-block:5rem;
}
.about-last-cta-wrap .body{
    display: flex;
    align-items: end;
}
.mobile-process-image-wrap{
    display: none;
}
.about-page-section-3{
    height: fit-content !important;
    padding-top:2em;
}
.about-page-section-2{
    height: fit-content !important;
    padding-top:2em;
}
.about-page-section-2 h2{
    margin:0;
}
.section-2-heading > div{
    display: flex;
    justify-content: space-between;
}
.section-2-heading-title{
    width: 50%;
}
.section-2-heading-subtitle{
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap:2em;
    width: 15vw;
}
.about-contact-block{
    margin-block:5rem;
}
.slide-projects-wrap:has(.slide-project.hovered) .slide-project{
    scale: 0.98;
}
.slide-project.hovered{
    scale: 1 !important;
}




@media only screen and (max-width:830px){
    .about-page *{
        cursor: default;
    }
    .about-page section, .portfolio-slide-subtext, .testimonial-slide-subtext{
        width:84vw;
        margin-inline:8vw;
        height: auto;
    }
    .section-2-heading > div{
        flex-direction: column;
        gap: 8vw;
    }
    .portfolio-slide-subtext{
        flex-direction: column;
        margin-bottom: 8vw;
    }
    .section-2-button-wrap{
        padding-top:16vw;
        gap: 4vw;
        display: flex;
        flex-direction: column;
    }
    .portfolio-slide-subtext > div,   .portfolio-slide-subtext p{
        width: 100% !important;
    }
    .section-1-heading{

        order:2;
    }
    .section-1-heading > div{
        width: 100% !important;
  
    }
    .section-1-content{
        height: calc(100% - 8vw - 80px);
        gap: 8vw;
    }
    .section-1-content > div > p{
        width:49%;
    }
    .scroll-notification{
        display: none;
    }
    .section-1-content > .section-1-image{
        height: auto !important;
        position: relative;
        aspect-ratio: 16 / 9;
        width: 100%;
    }
    .section-1-text-wrap{
        order:3;
        width: 100% !important;
    }
    .section-1-text{
        padding-top:8vw;
        width: 100%;
    }
    .slide-project{
        width: 84vw;
        max-width: 84vw;
        
    }
    .about-page-slide{
        height: 60vw;
        margin-block: 5rem;
    }
    .slide-project p{
        opacity: 1;
    }
    .slide-project p span{
        transition: ease-in-out 1s all 0.2s;
        transform: translateY(0%);
    }
    .portfolio-slide-subtext-right{
        align-items: start;
        height: 100px !important;
    }
    .about-process{
        margin-top:5em;
    }
    .about-process-wrap{
        width: 100%;
    }
    .testimonial-slide-subtext{
        flex-direction: column;
    }
    .testimonial-subtext-col-1{
        flex-direction: row;
        width: 100%;
    }
    .testimonial-click{
        display: none;
    }
    .testimonial-subtext-col-3{
        display: none;
    }
   
    .about-process{
        height: fit-content !important;
    }
    .about-process-wrap{
        display: none;
    }
    .about-process-slides{
        margin:0;
        width: 100%;
        height: fit-content;
        gap: 4em;
    }
    .about-process-slide{
        height:fit-content;
        gap:4vw;
    }
    .about-process-slide .about-process-slide-title{
        margin-top:0px;
    }
    .testimonial-subtext-col-2 > p{
        font-size: 11.2vw !important;
    }
    .about-process-slide-text{
        margin-left:14vw;
        width:calc(100% - 14vw)
    }
    .about-last-cta-wrap{
        margin-block:0px;
        display: flex;
        flex-direction: column;
    }
    .section-2-heading-title{
        width: 100%;
    }
    .section-2-heading-subtitle{
        width: 100%;
        gap: 4vw;
        padding-bottom:8vw;
    }
    .portfolio-slide-subtext-right{
        padding-top:8vw;
    }
    .portfolio-slide-subtext-right p{
        display: none;
    }
    .about-page-section-3{
        height: fit-content !important;
    }
    .about-last-cta-wrap-heading{
        font-size: 7.5vw;
        line-height: 11vw;
    }
    .testimonial-slide-subtext{
        margin-top: 5rem;
    }
    .mobile-process-image-wrap{
        width:84vw;
        height:fit-content;
        display: block;
    }
    .about-page-section-2{
        padding-top:0;
    }
    .mobile-process-image-wrap > img{
        border-radius: 20px;
        width: 100%;
        aspect-ratio: 1;
        object-fit: cover;
    }
}