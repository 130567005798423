@font-face {
  font-family: "Tusker-Second";   /*Can be any text*/
  src: local("TuskerGrotesk"),
    url("../../..//fonts/TuskerGrotesk.ttf") format("truetype");
}
  

.film-page-wrap{
    width:92%;
    margin-inline:4%;
 
    min-height:100vh;
    min-height:100vh;
    display: flex;
    flex-direction: column;
    
}










.film-page-wrap.fast-active{
      min-height:auto;
}
.view-bracket-wrap{
    transition: 1000ms cubic-bezier(0.76, 0, 0.24, 1) all;
  position:absolute;
  top:0;

  display: flex;
  padding-top: 20px;
  z-index: 21;
  width: 80%;
  left:10%;
  justify-content: space-between;
  pointer-events: none;

}



.film-page-title-wrap{
    
    width:92vw;
    height:fit-content;
    display: flex;
    justify-content: space-between;
    pointer-events: all;
    transition-delay: 500ms;
    transform: translateY(90px);
}
.film-page-title-wrap h2{
    margin: 0;
}
.film-page-intro-desc{
    margin-top:0px;
    
}
.film-page-title-wrap> h2, .film-page-title-wrap >div{
    width: 49.2%;
}
.film-page-intro-desc{
    width: 300px;
}
.film-page-wrap .film-page-title-wrap .film-page-title{
    margin-left: calc(8.5%);
    margin-bottom: 0;
}
.scrolled .film-page-title{
    opacity: 0;
    line-height: 60px;

}
.film-project-item{
    cursor: none;
    background-size: cover;
    background-position: center;
    position: relative;
    transition: 500ms background-image ease;
    height: calc(70vh - 90px);

}
.film-project-item::after{
    content: '';
    left:0;
    top:0;
    position:absolute;
background: rgb(0,0,0);
background: linear-gradient(90deg, rgba(0,0,0,0.5228466386554622) 0%, rgba(0, 0, 0, 0.05) 70%);
        width: 100%;
    height: 100%;
}
.film-project-item > h3, .film-project-item > h2, .film-project-item > div{
    z-index: 2;
    position: relative;
}
.film-project-item .film-project-cta::before{
    display: none;
}
.film-project-scroll-wrap{
    /* height: calc(100vh - 4vw - 84px); */

    display: flex;
    align-items: end;
}
.infinite-film-project-item:hover h2{
    transform: translateX(0.2em);
}
.view-list-item-link{
    min-height: calc(70vh - 88px);
    display: block;
}
.project-scroll-height a:last-child .film-project-item{
    border-radius: 0px 0px 20px 20px;
}
.loaded-image{
    position:absolute;

}
.film-project-scroll-container{

    max-height: calc(100vh - 4vw - 84px);
    height: calc(-90px + 70vh);
    overflow-x: hidden;
    border-radius: 18px;
    width: 100%;
    overflow-y: hidden;

    pointer-events: all;
    display: flex;
    align-items: start;
}
.film-project-item p{
    margin-block: 0em;
}
.film-project-item{
    padding-left:26px;
    width: calc(100% - 26px);
    height: calc(70vh - 87px);
    display: flex;
    flex-direction: column;
    margin-block: -1px;
    justify-content: end;
    overflow: hidden;

}
.film-project-item > img{
    position: absolute;
    left:0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}
.film-project-item *{
    cursor: none !important;
}
.project-scroll-height a:first-child .film-project-item{
    justify-content: end;
}
.film-project-desc-item{
    color: white;
    margin:0;
}
.film-project-desc-item::after{
    filter: invert(1);
}

.film-project-item > div{
    padding-block:1em;
}
.film-project-title{

    margin: 0;
    color: white;
    padding-bottom: 8px;
}
.film-project-title, .film-project-title span{
    font-family: var(--second-family) !important;
    letter-spacing: -0.02em !important;
}
.film-project-cta::after, .film-project-cta::before{
    background-color: black;
}
.film-project-item:hover .film-project-cta::after{
    transform: translateY(-24px) !important;

}
.view-switcher-wrap{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    position: fixed;
    top: calc(100vh - 8vw - 140px);
    right: 4vw;
    z-index: 20;
    pointer-events: all;
    height: 100px;
    padding: 10px;
    padding-block:20px;
    margin:2vw;
    width: fit-content;
    background-color: white;
}
.view-switcher-wrap button{
    background-color: white;
    border: none;
    cursor: pointer;
    transition: 500ms ease all;
}
.view-switcher-wrap:hover button{
    opacity: 0.5;
}
.view-switcher-wrap > button:hover{
    opacity: 1;
}

button.view-toggle-active{
    opacity: 1;
}

.project-nav-wrap{
    position: relative;
    
    
    width: 100%;
   
    /* padding-top: calc(4vw + 84px) !important ; */
}
.film-page-wrap:has(.slow-view) .project-nav-wrap{
    height: calc(100vh - 4vw - 84px) !important;
    top: auto !important;
    bottom: 4vw;
    display: flex;
    align-items:end;
}
.film-page-wrap:has(.film-project-scroll-wrap) .infinite-scroll-map-wrap, .film-page-wrap:has(.film-project-scroll-wrap) .random-masonry-image-view{
    position: absolute;
    top: 0;
}

.project-wrap, .film-project-scroll-wrap{

    width: 92vw;
}
.project-wrap:has(.infinite-film-project-item){
    width:64vw;
}
.random-masonry-image-view, .infinite-scroll-map-wrap{
    margin-top:10vw;
}
.infinite-project-scroll-wrap{
    pointer-events: all;
}
.fast-project-wrap{
    position: relative;
}
.project-scroll-height{
    width: 100%;
    overflow: hidden;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
}
.infinite-film-project-item{
    height:fit-content !important;
    width: fit-content;
}
.infinite-film-project-item h2{
    transition: all 500ms ease, font-size 0ms, line-height 0ms;
    width: fit-content;
    cursor: none;
    padding-top:0;
    margin:0;
}

.film-page:has(.slow-toggle.view-toggle-active) .view-bracket-wrap{
    top:0;
    width: 80%;
    left:10%;
  }
.film-page:has(.fast-toggle.view-toggle-active) .view-bracket-wrap{
    top:29%;
    width: 72%;
    left:15%;
  }
  .film-page:has(.medium-toggle.view-toggle-active) .view-bracket-wrap{
    top:57%;
    width: 70%;
    left:15%;
  }

  .film-page:has(.slow-toggle:hover) .view-bracket-wrap{
    top:0;
    width: 80%;
    left:10%;
}
.film-page:has(.medium-toggle:hover) .view-bracket-wrap{
    top:57%;
    width: 70%;
    left:15%;
}
.film-page:has(.fast-toggle:hover) .view-bracket-wrap{
    top:29%;
    width: 72%;
    left:15%;
}
.infinite-project-scroll-wrap{
    position: relative;
}
.film-page-intro-desc-wrap{
    display:flex;
    align-items: end;
}
.random-masonry-image{
    border-radius: 14px;
      
    width:100% !important;

}
.floating-project-thumb-wrap{
    opacity: 0;
    position: fixed;
    pointer-events: all;
    right: 0;
    width: 22vw;
    height: 22vw;
    padding-bottom:0vw;
    border-radius: 16px;
    margin-right:4vw;
    margin-top:90px;
    overflow: hidden;
    cursor:none;
    z-index: 8;
    display: block;
    transition: 500ms ease all 500ms;
    pointer-events: none;

}
.floating-project-thumb-wrap > div{
    padding: 0 !important;
    height: 0vw;
    overflow: hidden;
    border-radius: 16px;
    transition: 500ms ease all;
}
.fast-view-button{
    filter: invert(1);
    margin-top:-50px;
    margin-left: 28px;
    overflow: hidden;
    pointer-events: none;
}
.fast-view-button > p{
    transform: translateY(100%);
    transition: 500ms ease all;
    margin-bottom: 0px;
    color: black;
}
.fast-view-button > p::after{
    background-color: black;
}
.floating-project-thumb-wrap:hover .fast-view-button > p{
    transform: translateY(0%);
}
.floating-project-thumb-wrap img, .floating-project-thumb-wrap div{
   width: 100%;
}
.infinite-film-project-item{
    padding-bottom: 0.2em;
}
.floating-project-thumb-wrap img{
    pointer-events: all;
    aspect-ratio: 1;
    cursor: none;
}
.infinite-scroll-map-wrap {
    width: 64vw;
}
.random-masonry-image-view{
    width: 92vw;
}
.random-masonry-image-view *{
    cursor: none;
}
.random-masonry-image{
    cursor: none;
    width:100% !important;
    scale:1.03;
    transition: all 0.2s ease;
    object-fit: center center;
}
.random-masonry-image:hover{
    scale:1.02;
}
.random-masonry-image-view *{
    cursor: none;
}
.film-page-title-wrap div:has(h2){
    width: 49.2% !important;
}
.film-page-wrap .pin-spacer{
    padding:0;
}
.scroll-notification{
    color:black;
    opacity: 1 !important;
    right:0;
    bottom:0;
    margin:0 !important;
    width: 14.5vw !important;
    pointer-events: all;
    padding-bottom: 18px;
    transition-delay: 0ms !important;
    display: flex;
    justify-content: flex-end;
    cursor: auto;
}
.floating-project-thumb-wrap .parallax-image-wrap{
    height: 22vw;
}
.parallax-image-wrap{
    clip-path: inset(0 0 0 0 round 14px);

    /* height: 22vw; */
    width: 22vw;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    transition: all 500ms ease;
}
.parallax-image-wrap:hover{
    transform: scale(0.99);
}
.parallax-image-wrap img{

    width: 22vw;
    object-fit: cover;
}
@media only screen and (max-width:830px){
    .film-page-title-wrap{
        flex-direction: column;
        gap: 4vw;
        width: 100%;
    }
    .floating-project-thumb-wrap{
        display: none;
    }
    .film-page *{
        cursor: default !important;
    }
    .film-page-wrap{
        width: 86%;
        margin-inline: 8%;
    }
    .film-page-wrap .scroll-notification{
        display: none;
    }
    .film-page-title-wrap > div{
        width: 100%;
    }
    .project-wrap{
        width: 100%;
    }
    .project-nav-wrap:has(.film-project-scroll-wrap){
        transform: translateY(-90px);
    }
    .infinite-scroll-map-wrap{
        margin-top:120px !important;
        display: flex;
        flex-direction: column;
        gap:0.6em;
        width:100%;
        margin-bottom: 8vw;
    }
    .random-masonry-image-view{
        margin-top:120px !important;
        margin-bottom: 8vw;
    }
    .random-masonry-image-view a *{
        width: 100%;
    }
    .random-masonry-image-view > div > div{
        gap:2vw !important;
    }
    .random-masonry-image-view > div > div > div{
        gap:4vw !important;
    }
    .film-project-item > div{
        width: calc(100% - 1em);
    }
    .project-wrap:has(.infinite-film-project-item){
        width:100%;
    }
}