.project-title{
    margin-top:120px;
    line-height: 120px;
    margin:0px;
    margin-bottom: -10px;


}
.projectPageSection1 *{
    color: var(--primary-color);
}
.projectPageSection1{
    width:92vw;
    height:calc(100vh - 4vw);
    margin: 4vw;
    margin-top: 0;
    display: flex;
    gap:1.5%;
    background-color: white;
    position: relative;
}
.projectPageSection1 > div{
    margin-top: 90px;
    width: 49.2%;
    display: flex;
    flex-direction: column;
    justify-content: start;

}
.projectPageSection1 >a{
    margin-top: 90px;
    width: 49.2%;
    display: flex;
    flex-direction: column;
    justify-content: start;
}
.single-image{
    position: absolute;
    left: 0;
}
.hiddenPreloadedBlurhash {
  display: none;
}
  
.imageWrap{

    width: 100%;
    position: relative;
    justify-content: start !important;
    max-height: calc(100vh - 4vw - 140px);
    cursor: none;
}
.imageWrap img{
    width: 100%;
    max-height: calc(100vh - 4vw - 140px);
    object-fit: cover;
    border-radius: 16px;
}
.buttonWrap{
    position: absolute;
    bottom:0 !important;
    width: 100% !important;
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: end;
}
.thumbnailWrap{
    display: none !important;
}
.buttonWrapRight, .buttonWrapLeft{
    gap:1em;
    display:flex;
}
.navigationButtonPrev:hover::after{
    transform: translateY(-24px) scaleX(-1);
}
.navigationButtonPrev::after{
    transform: scaleX(-1);
    left: 5px;
    right:auto
}
.navigationButtonPrev{
    padding-left: 38px;
    padding-right: 0px;
    
}

@media only screen and (max-width:830px){
    .projectPageSection1{
        width: 84%;
        margin-inline: 8%;
        flex-direction: column;
        justify-content: space-between;

    }
    .projectPageSection1 > div{
        width: 100%;
    }
    .imageWrap{
        margin-top: 0px;
        margin-bottom: 90px;
    }
}