.main-description > span:nth-child(1){
    transition-delay: 600ms;
    transition: all 900ms ease;
}
.main-description > span:nth-child(2){
    transition-delay: 500ms;
    transition: all 800ms ease;
}
.main-description div:nth-child(1){
    transition-delay: 400ms;
    transition: all 700ms ease;
}
.main-description .text-reveal-container{
    margin:0 !important;
}


.scrolled .main-description > span:nth-child(1){
    opacity: 0;

    transition-delay: 0ms;
}
.scrolled .main-description > span:nth-child(2){
    opacity: 0;

    transition-delay: 200ms;
}
.scrolled .main-description div:nth-child(1){
    opacity: 0;

    transition-delay: 300ms;
}

.scrolled .scroll-notification-single{
    opacity: 1 !important;
}
body:has(.player__wrapper:hover) .scroll-notification{
    opacity: 1;
  
}
.scroll-notification-single p div{
    display: flex;
    overflow: hidden;
    transition: all 800ms ease;
    transition-delay: 400ms;
    height:20px
}
.scrolled .scroll-notification-single p div{
    transition-delay: 0ms;
    height:0px;
}


.main-section-image-overlay img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}
.main-single-heading{
    overflow: hidden;
}
.main-description-right-wrap .primary-button{
    transition-delay: 0ms !important;
}
.scroll-notification-single{
    height: 20px;
    overflow: hidden;
}
.scroll-notification-single p{
    height: 20px;
    overflow: hidden;
}
.scroll-notification-single p span{
    transition: all 1000ms;
    text-align: right;
    width: 3.6em;
}
.scroll-notification-single p span::after{
    width: 100%;
    text-align: right;
}
.scrolled .scroll-notification-single p span{
    transition: all 1000ms;
    width: 12.5em;
    height: 0;
    animation: none;

}
.scrolled .scroll-notification-single:has(.play-text-toggle) p div span{
    width: 13em;
}
.scrolled .progress-bar{
    clip-path: inset(0 0 0 0 round 20px);
}
.stop-progress-toggle{
    clip-path: inset(0 100% 0 0 round 20px);
    transition-delay: 0ms;
    margin-right: 60px !important;
    opacity: 0 !important;
}
.scrolled:has(.main-section-image-wrap:hover) .large-arrow-cursor{
    transform-origin: top left;
    transform: scale(0.2) !important;
    margin-top: -48px;
    margin-left: -38px;
}
.scrolled:has(.main-section-image-wrap:hover) .arrow-cursor-image{
    transition: 0ms 500ms;
    background: var(--primary-color);

}
.stop-progress-toggle > div{
    clip-path: inset(0 100% 0 0 round 20px);
}
.single-click-anywhere{
    position: absolute !important;
    width: 100%;
    transition-delay: 200ms;
    transition: 500ms ease;
}
.single-click-anywhere>p{
    padding-left: 3px;
}

.play-text{

    display: flex;
    flex-direction: column;
}
.play-text p{
    transition: 500ms ease all;
    min-height: 20px;
}
.scrolled .project-page .scroll-notification{
    margin-bottom: 46px;
}
.main-video-controls-overlay:not(:has(.stop-progress-toggle)) .main-description-time-wrap{
    opacity: 1;
}
.scrolled .play-text-toggle p{
    transition: 500ms ease all 300ms;
    transform: translateY(-20px);
 
}
.play-text::after{
    content: 'pause';
    position: absolute;
    transform: translateY(100%);

}
.scrolled .single-click-anywhere{
    transition-delay: 600ms;
    transform: translate( 8px , -20px);

}
.scroll-notification-single p span::after{
    transition-delay: 800ms !important;
}
.main-description-right-wrap .primary-button::after{
    transition: 500ms ease all !important;

}
.scrolled .main-description-right-wrap .primary-button::after{
    transform: translateY(50px ) !important;

}
.main-description-right-wrap:hover .primary-button::after{
    transform: translateY(-24px);
}
.masonry-column{
    height: fit-content;
}
 


  .video-hover-desc{
      height:fit-content;
      min-height: 1em;


      transform-origin: top left;
      transition: transform 500ms ease, clip-path 500ms ease, max-height 500ms ease, max-width 500ms ease, padding 500ms ease, width 500ms ease !important;
      z-index: 101;
      scale:1;

      padding:12px 14px 12px 42px;

      pointer-events: none !important;

      clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
      border-radius: 4px;
      overflow: hidden;
      pointer-events: none;
  }

  .video-hover-svg-wrap{
    aspect-ratio: 1;
    overflow: hidden;
    position: absolute;
    left:16px;
    top:14px;
}
.video-hover-svg-wrap > svg{
    
    transform: scaleX(-1);
}
.video-hover-svg-wrap > svg > path{
    transform: translateY(100%);
    transition: 500ms ease;
    fill: var(--primary-color);
}
body:has(.main-section-image-wrap:hover) .video-hover-svg-wrap > svg > path{
    transform: translateY(0%);
}
body:has(.project-page) .large-arrow-cursor{
    transition: transform 500ms ease 400ms, max-height 500ms ease, max-width 500ms ease, margin 500ms ease 400ms, filter 300ms ease 0ms, scale 300ms ease;
}
.scrolled:has(.project-page) .large-arrow-cursor{
    transition: transform 500ms ease 0ms, max-height 500ms ease, max-width 500ms ease, margin 500ms ease 0ms, filter 300ms ease 0ms, scale 300ms ease;
}
  
  .scrolled:has(.main-section-image-wrap:hover) .video-hover-desc{
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
  .scrolled .main-video-controls-overlay div{
    pointer-events: none !important;
  }
  .scrolled:not(:has(.main-section-image-wrap:hover)) .video-hover-desc{
      transition: 0s 0.7s, opacity 0.6s 0.1s, clip-path 0.5s 0.1s !important;
      opacity: 0;
      clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);


  }
  .video-hover-desc > .link-desc span>span{
      font-size: 0.6em !important;
      vertical-align: text-top;
      transform: translateY(0.1em);
      display: inline-block;
  }
  .video-hover-desc > .link-desc{
      margin:0 !important;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      position: relative;
      display: flex;
      min-height: auto;
      margin-top:-2px !important;
      transition: clip-path 500ms ease-out 0s, color 500ms ease 0ms, width 500ms ease !important;
      pointer-events: none !important;
  }
  .video-hover-desc::before{


      content: '';
      width: 100%;
      height: 100%;
      background-color: white;
      position: absolute;
      top:0;
      left:0;
      display: block;
      transition: 700ms ease-out all, clip-path 200ms ease-out !important;
  }

  .video-hover-desc > .link-desc > span{
      min-width: max-content;

      text-align: right;

      font-size: 1.30em;
      min-height: 1em;
      line-height: 1em;

      pointer-events: none !important;
      position: relative;

      clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
      pointer-events: none;
      transition-delay: 0.2s;
     transform: translateY(0%);
     color: var(--primary-color);
     margin-top:0;

  }

  .scrolled:not(:has(.main-section-image-wrap:hover)) .video-hover-desc  .link-desc > span{
      transition: 500ms ease all !important;
      transform: translateY(100%) !important;
      color: white !important;
  }
  .scrolled:has(.main-section-image-wrap:hover) .video-hover-desc{
      transition: transform 500ms ease 0s, width 500ms ease 0s, max-height 500ms ease 0s, max-width 500ms ease 0s, padding 500ms ease 0.5s, clip-path 500ms ease 0s !important;
      clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%) !important;
  }
  .scrolled:has(.main-section-image-wrap:hover) .video-hover-desc > p  > span{
      transition: clip-path 500ms ease-out 0s, filter 500ms ease 0ms !important;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%) !important;
  }
  .video-hover-desc p{
      margin:0 !important;
  }
  .pin-spacer:has(> .main-section-image-wrap),  .pin-spacer:has(> .main-section-image-wrap) .main-section-image-wrap{
    width: 92vw !important;
  }
  .pin-spacer:has(> .main-section-image-wrap){
    margin-inline: 4vw !important;
  }


  @media only screen and (max-width: 830px){
    .project-page *{
        cursor: auto !important;
    }

    .project-page section,  .main-section-image-wrap{
        width: 84vw;
        margin-inline: 8vw;
    }
    .main-section-image-wrap{
        align-items: start;
        margin-top:calc(40px + 8vw);

    }
    .project-page .masonry-column{
        gap:4vw !important;
    }
    .scrolled .main-description > span:nth-child(1), .scrolled .main-description > span:nth-child(1) *, .scrolled .main-description div:nth-child(1), .main-description div:nth-child(1), .scrolled .main-description > span:nth-child(2){
        opacity: 1 !important;
    }

  }