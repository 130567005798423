.all-projects-page-wrap{
    width: 92svw;
    min-height:calc(100svh - 120px);
    display: flex;
    justify-content: end;
    padding-top:120px;
    margin-inline:4vw;
    gap:1.6vw;
}
.all-projects-content{
    max-width: 1100px;
    width:90vw;
    height:min-content;

    display: flex;
    gap:5%;
}
.all-projects-list{
    width:47.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    border:1px solid var(--outline-color);
    background-color: white;
    overflow: hidden;
}
.all-projects-list-title{
    height:120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin-inline: 20%;
}
.list-wrap{
    height:fit-content;
    overflow-x: hidden;

    width: 100%;
}
h2{
    font-family: var(--title-family);
    text-transform: uppercase;
    font-size: 36px;
}
.list-item{
    height: 64px;
    border-radius: 5px;
    border: 1px solid var(--outline-color);
    width: calc(100% - 20px);
    margin: 5px;
    display: flex;
    justify-content: space-between;
    background-color: white;
  
    align-items: center;
}
.list-item-title{
    margin-left:32px
}
.list-item-actions{
    opacity: 0;
    margin-right:32px;
    display: flex;
    gap:1em;
    transition-delay: 100ms;
    transition: all 100ms ease;
}
.list-item-actions  div, .add-project{
    height:24px;
    aspect-ratio: 1;
    cursor: pointer;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.list-item:hover .list-item-actions{
    opacity: 1;
}
.view-list-item{
    background-image: url(/public/icons/view.svg);
}
.edit-list-item{
    background-image: url(/public/icons/update.svg);
}
.delete-list-item{
    background-image: url(/public/icons/remove.svg);
}
.add-project{
    background-image: url(/public/icons/add.svg);
}
.edit-list-item-link{
    width: 100%;
    height:100%;
}
.update-projects-content, .add-projects-content{
    width: 68.5vw;
}
.update-projects-list,.add-projects-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    border:1px solid var(--outline-color);
    background-color: white;
    overflow: hidden;
    padding: 50px;

}
.update-projects-list>form, .add-projects-list>form{
    width: 100%;
}
.update-projects-list>form  .formInput, .add-projects-list>form  .formInput{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.add-projects-list>form .formInput > label, .update-projects-list>form .formInput > label{
    font-family: var(--title-family);
    text-transform: uppercase;
    font-size: 28px;
}
.add-projects-list>form  .formInput > input, .update-projects-list>form  .formInput > input{
    border: 0;
    border-bottom: 2px solid var(--outline-color);
    width: 50%;
}
.add-projects-list>form .formInput > input:focus, .update-projects-list>form  .formInput > input:focus{
    outline: none;
    border-bottom: 2px solid var(--primary-color);
}
.uploaded-section-wrap{
    width:100%;
    outline: 1px solid var(--outline-color);
    overflow: hidden;
    margin-top: 32px;
    border-radius: 8px;
    transition: 500ms ease all;
}
.uploaded-section-wrap:hover{
    outline: 1px solid var(--primary-color); 
}
.uploaded-section-wrap>div{
    padding: 16px;
}
.uploaded-section-wrap h2{
    margin-top: 0px;
}
.update-projects-list>form  button, .add-projects-list button{
    cursor: pointer;
    margin-top: 40px;
    width: 32%;
    background-color: white;

    border-radius: 20px;

    padding: 10px;
    color: black;
    font-family: var(--body-family);
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: -1.5px;
    position: relative;
}
.add-projects-list button:hover{
    border: 2px solid rgb(67, 67, 67) !important;
}
.add-button-grid{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1%;
}
.section-title, .add-projects-list h1{
    font-family: var(--body-family);
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: -1.5px;
    color:var(--primary-color);
    margin-bottom: 30px;
    opacity: 0.5;
}
.list-item-title{
    max-width: 70%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.add-projects-list .formInput img{
    max-height: 50px;
 
}
.image-section img{
    max-height: 200px;
    width: auto;
    border-radius: 20px;
    max-width: 70%;
}
.list-map-wrap{
    display: flex;
    justify-content: space-between;
    width:calc(50% - 2.4svw);
    
}
.file-label{
    border-radius: 0px;
    position: relative;
    display: block;
    height: 24px !important;
    overflow: hidden;
}

.uploaded-image-section-wrap > div > div{
    display: flex;
    flex-wrap: wrap;
    gap: 1.2svw;
}
.image-section-title > span{
    display: block;
    margin-bottom: 64px !important;
    margin-top:16px;
    font-size: 2em;
}
.section-title{
    font-size: 2em;
}
.add-projects-list .formInput{
    padding-top: 10px;
    padding-bottom: 20px;
}
.add-projects-list .formInput input{
    font-size: 2em;
 
    font-weight: 400 ;
    
}
.add-projects-list input[type="color"] {
    -webkit-appearance: none;
    border: none !important;
    border-radius: 20px;
}
.add-projects-list input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
    border-radius: 20px;
}
.add-projects-list input[type="color"]::-webkit-color-swatch {
    border: none !important;
    border-radius: 20px;
}
.add-projects-list input{
    color: rgb(67, 67, 67) !important;
}
.add-projects-list input:active, .add-projects-list input:focus{
    color: black !important;
}
.project-sidebar{
    width: 22vw;
    height: calc(100vh - 240px);
    position: sticky;
    top:0;
    left: 0;
    padding-top:120px;
    margin-top: -120px ;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.sidebar-bottom svg:first-child{
    opacity: 1 !important;
}
.sidebar-bottom svg{
    position: absolute;
    top:0;
    opacity: 0;
    transition: 500ms ease all;
}

.project-sidebar > div{
    height: 31%;
    width: 100%;
    position: relative;
}
.drag-list{
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 10px;
    padding: 10px;
    border: 4px solid lightgrey;
    border-radius: 10px;
}
.drag-item{
    border: 3px solid lightgrey;
    background-color: white;
    padding: 20px;
    margin-bottom: 5px;
    border-radius: 5px;
    position: relative;
    cursor:grab;
}
.drag-item:first-child{
    cursor:auto;
}
.drag-item:after{
    content: '';
    background-image: url(/public/grabable.svg);
    height: 50%;
    right: -5%;
    top:25%;
    opacity: 0.5;
    width: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;

}
.drag-item:first-child:after{
    display: none;
}
.sidebar-top{
    height:10% !important
}
.sidebar-middle{
    height:50% !important;
}
.all-projects-content:has(.all-projects-list){
    max-width: none;
}
.add-featured-button.featured-active path, .remove-featured-button path{
    fill: black;
}
.uploaded-image-preview{
    width: 100%;
    max-height: 400px;


    border-radius: 10px !important;
    overflow: hidden;
    object-fit: cover;
    transition: 1000ms ease all;
    position: relative;
    z-index: 2;
}
.section-title.image-preview{
    color:white;
    padding-inline: 20px;
    transform: translateY(-100%);
    opacity: 1;
    position: relative;
    z-index: 2;
  
}
.uploaded-image-wrap{
    width: 100%;
    position: relative;
    
}
.uploaded-image-wrap:hover .uploaded-image-preview{
    width: 40%;

    
}
.uploaded-image-wrap > p{
    position: absolute;
    top:50%;
    right:30%;
    width: 12vw;
    transform: translateY(-50%);
}
.uploaded-image-wrap > p > span{
    font-size: 2em;
    display: block;
}