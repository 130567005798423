.minimal-header{
    position: relative !important;
    top:0;
    left: 0;
    z-index: 8000;
    height:120px;
    min-width: 100%;

    background-color: transparent;


}

.minimal-header *{
    
    pointer-events: none;
}
.minimal-header.header-toggled *{
    pointer-events: all !important;
}
.minimal-header-background{

    height: 100vh;
    padding-bottom: 20px;
    position: absolute;
    top:0;
    transform: translateY(-100%);
    left:0;
    width: 100%;
    background-color: var(--secondary-dark);
    border-radius: 0px 0px 20px 20px;
    z-index: 9000;
    transition: background-color 200ms ease 2000ms;

}
.header-toggled .minimal-header-background{
    transform:translateY(0);
}
.header-toggled .header-inner-content{
    height:120px;
    cursor: default;
}

.header-wrap{
    width: 100%;
    min-height: 0px;
    height:0px;
    transition:all 600ms ease-in-out  1s, min-height ease-out 200ms ;
}
.header-menu-link:hover::after{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    height: 1vw;
    transition: 200ms ease-in-out all 100ms;
}

.header-menu-link::after{
    content:'';
    aspect-ratio: 1;
    height:0.5vw;
    background-color: #ffffff;
    position: absolute;
    top: 20px;
    left: 0.2em;

    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    transition: 200ms ease-in-out all 0ms;
    border-radius: 2px;

}
.header-toggled .header-cta-wrap svg > path{
    pointer-events: all !important;
}
header > div{
    position: fixed !important;
}
.header-menu-link {
    display: flex;
    letter-spacing: 2px;
    overflow: hidden;
    padding-left: .2em;
    margin-left: -.2em;
    width: fit-content;
}
.minimal-header > .header-hover-desc{
    height:fit-content;
    min-height: 1em;
    width: 60px;
  
    transform-origin: top left;
    transition: transform 500ms ease, max-height 500ms ease, max-width 500ms ease, padding 500ms ease, width 500ms ease, margin 500ms ease !important;
    z-index: 9999;
    scale:1;

    padding:12px 14px 12px 42px;

    pointer-events: none !important;
    
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    border-radius: 4px;
    overflow: hidden;
    
}

.minimal-header > .header-hover-desc::before{


    content: '';
    width: 100%;
    height: 100%;
    background-color: var(--secondary-dark);
    position: absolute;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    top:0;
    left:0;
    display: block;
    transition: 700ms ease-out all, clip-path 200ms ease-out;
}


.header-subtext-link{
    cursor: pointer !important;

}
.menu-time-wrap > p{
    cursor: default !important;
}
.minimal-header:has(.header-menu-link:hover:not(:last-child)) > .header-hover-desc::before{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

}
.minimal-header:has(.header-menu-link:last-child:hover) > .header-hover-desc::before{
    transition: 700ms ease-out all, clip-path 500ms ease-out 500ms;

}
.header-hover-desc::after{
    content: '';
    width: 100%;
    height: 100%;
    background-color: #F2E918;
    position: absolute;
    top:100%;
    left:0;
    display: block;
    transition: 500ms ease-out all 0s;
    border-radius:  10px 10px 0px 0px;

}
.minimal-header > .header-hover-desc > .link-desc{
    margin:0 !important;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    position: relative;
    display: flex;
    min-height: auto;
    transition: clip-path 500ms ease-out 0s, color 500ms ease 0ms, width 500ms ease !important;
    pointer-events: none !important;
    color: white;
}
.minimal-header > .header-hover-desc > .link-desc > span{
    min-width: max-content;
    text-align: right;
  
    font-size: 1.30em;
    min-height: 1em;
    line-height: 1em;

    pointer-events: none !important;
    position: relative;
  
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    pointer-events: none;
   
   

}
.minimal-header:has(.header-menu-link:nth-child(2):hover) .header-hover-desc {
    padding: 12px 14px 12px 14px !important;
}
.minimal-header:has(.header-menu-link:nth-child(5):hover) .header-hover-desc{
    padding: 12px 14px 12px 16px;
    margin-top:-1.38em;
 
}
.minimal-header:has(.header-menu-link:nth-child(5):hover) .header-hover-desc::after{
    transition: 200ms ease-out all 0.5s;
    transform: scaleY(1.4);
    top:0;
}

.minimal-header:has(.header-menu-link:nth-child(5):hover) .header-hover-desc > .link-desc {
    
    z-index: 9999;
}
.minimal-header:has(.header-menu-link:nth-child(5):hover) .header-hover-desc > .link-desc{
    color: #181818;
}
.header-inner-content{
    height: 40px;
    padding-top: 0px;
    width:92vw;
    margin:auto;
    padding-inline: 4vw;
    display: flex;
    justify-content: space-between;
    align-items:center;
    position: fixed;
    top:0;
    left: 0;
    z-index: 9999;
    transform: translateY(0) !important;
    padding-top: 22px;

}
.header-toggled .header-inner-content .header-hamburger *{
 cursor: pointer !important;
}
.header-inner-content .header-logo, .header-inner-content .header-hamburger{
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    pointer-events: all !important;
    z-index: 9999;
   
}
.header-hamburger{
    transition: all 500ms ease-out;
    transform-origin: 5px;
    cursor: pointer !important;
    height: 26px !important;

}
.header-hamburger{
    pointer-events: all;
    transform: scale(1.1);
    transform-origin: bottom right;
    max-height: 1000px;
    z-index: 9999;
    height: auto;
}
.header-logo{
    position: relative;
    z-index: 9999;
    cursor: default;
}
.header-logo >a{
    pointer-events: all;
    cursor: pointer;
}
.header-menu{
    
    position: fixed;
    bottom:0;
    width:92%;
    padding-inline:4vw;
    margin-bottom:5em;
    z-index: 9010;
    
    pointer-events: none;
    display: flex;
    justify-content: space-between;

}
.header-menu-links{
    bottom:0;
    position: relative;
    max-width: calc(75vw);
    width: max-content;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: clip-path 1000ms ease 800ms;
}
.header-toggled .header-menu-links{
    transition: clip-path 1000ms ease;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

}
.header-menu-link{
    margin-top:-20px;
}
.header-menu-links>a:last-child{
    margin-bottom: -10px;
}

.header-menu-link{
    text-decoration: none;
    font-weight:900;

    font-family: var(--title-family);
    font-size: 7.8vw;

    text-transform: uppercase;
    line-height: 1.01em;
    padding-top:25px;

    color: var(--secondary-color);
    position: relative;
    transition: all 500ms ease, font-size 0ms, line-height 0ms;
}


.reference-peace-sticker-top, .reference-peace-sticker-bottom{
    height:20px;
    overflow: hidden;
    width: 80%;
}
.reference-peace-sticker-top span, .reference-peace-sticker-bottom span {
    display: block;
    font-size: 21px;
    line-height: 1em;
    height: 1.6em;
}
@media screen and (min-width:1300px){
    .header-menu-link{
        font-size: 7.2vw !important;
    }
    .reference-peace-sticker{
        bottom:13vh;
        transition: 0ms ease-out all 0.6s, opacity 400ms ease 0.05s, height 400ms ease 0.15s;
        transform: rotate(0deg) scale(0.8) translateY(20px);
    }
    .header-toggled .reference-peace-sticker{
        transform: rotate(10deg) scale(1) translateY(0px);
    }
  
}
@media screen and (min-width:2200px){
.header-menu-link{
    font-size: 14vh !important;
}
}
.header-menu-link:hover{
    padding-left: 0.4em;
}
.header-menu-link > a{
    display: flex;
}
.header-logo{

    transition: all 400ms ease;
    transition-delay: 1100ms;
}
.header-toggled .header-logo {


    transition-delay: 300ms;
    filter: invert(1);
}

.header-logo div{
    
    background-color: var(--primary-color);
    width: 45px;
    aspect-ratio: 1;
    mask-image: url(/public/LOGO-DESKTOP.svg);
    mask-repeat: no-repeat;
    mask-size: contain;
    transition: ease all 100ms 400ms, width 750ms ease;

 
}
.header-logo img{
    position: absolute;
}
.header-toggled .header-logo >a{
    transform: scale(1) translate(0) rotate(0);
}
.header-toggled .header-logo div{
    transition-delay: 200ms;
    filter: drop-shadow(0px 0px 0px white);
 }


.header-menu-subtext{
    justify-content: end;
    max-width: 30%;
    width: fit-content;

    display: flex;
    
}
.header-menu-subtext > div{

    width: 14.3vw;
    height:100%;
    
}

.header-menu-subtext-links {
    position: absolute;
    bottom:0;
    display: flex;
    flex-direction: column;
    gap:0.3em;
    width: 14.3vw;
}

.header-menu-subtext-links > p{
    color: white;
    cursor: auto;
}
.header-menu-subtext-links > a{

    inline-size: 12vw;
    line-height: 1.02em;
    max-height: 1.1em;
    position: relative;

    
}
.header-subtext-link::after{
    width: 17px !important;
    scale:1;
}
.header-menu-subtext-links > a::after{
    margin-top:0.1em;
    background-repeat: space;
}
.empty-menu-link{
    margin-top: 0 !important;
}
.header-menu-subtext-links > a::after{
    background-image: url(/public/Arrow/White-Arrow.svg) !important;
    max-height: 45px !important;
}
.header-menu-subtext-links > a:hover::after{
    transform: translateY(-22px);
  }
.header-menu-subtext-links > a, .header-menu-subtext-links > p{
    opacity: 0;
    transition: ease-out 500ms all;
   
}
.header-toggled .header-menu-subtext-links > a, .header-toggled .header-menu-subtext-links > p{
    transition-delay: 600ms;
    opacity: 1;
}
.header-menu-subtext-links > a:active{
    transform: translateY(0.2px);
    transition: all 50ms ease;
}
.empty-menu-link{
    opacity: 0;
    pointer-events: none !important;
    transition: all 500ms ease;
}
.menu-time{
    transform:translatey(10px);

}
.menu-time > .menu-location{
    opacity: 0;
    transition: all 500ms ease;
    transition-delay: 250ms;
}
.menu-time > .menu-country{
    opacity: 0;
    transition: all 500ms ease;
    transition-delay: 800ms;
}
.menu-time:hover > .menu-location{
    opacity: 1;
}
.menu-time:hover > .menu-country{
    opacity: 1;
}
.menu-location{
    display: inline !important;
}
.menu-country{
    
    display: inline !important;
}
.menu-country{
    padding-right: 3px;
}
.menu-time-wrap{
    position:fixed;
    top:0;

    
}
.reference-peace-link{
    position: relative;
}

.menu-time-wrap{
    padding-top: 50px;
}
.scrolled .menu-time-wrap{
    padding-top: 10px;
    margin-top: 50px !important;
}

.menu-time-wrap{
    opacity: 0;
    transition: 500ms ease-out all 0s !important;
}
.header-toggled .menu-time-wrap{
    opacity: 1;
}
.header-subtext-link > span{
    min-width: 4px;
}
.header-menu-subtext > div > div > a, .menu-time{
    text-decoration: none;
    font-weight:500;
    line-height: 22px;


    color: var(--secondary-color);
}
.menu-time-func{
    margin-bottom:-8px;
    text-transform: uppercase;
    

}
.colon{
    transition: all 200ms ease;
    display: inline !important;
}

.header-toggled .header-hamburger{
    filter: invert(1);

 }

 .header-menu-links:has(.header-menu-link:last-child:hover) .reference-peace-sticker{
    margin-right: -50px;
 }
.reference-peace-sticker-bottom{
    display: flex;
    justify-content: space-between;
}
.reference-peace-sticker-bottom-asterix{
    font-size: 45px;
    height: 16px;
    line-height: 30px;
    transform: translate(-4px, 2px);

}
.header-toggled .reference-peace-sticker-bottom-asterix{
    animation: spin 2s infinite ;
}
.sticker-top-line{
    min-height:4em
}


/* Define keyframe animation */
@keyframes infiniteEaseLoop {
    0%, 100% {
        /* Start and end with opacity: 1 */
        opacity: 1;
      }
      50% {
        /* Midway point with opacity: 0.5 */
        opacity: 0;
      }
  }


  
  /* Apply the animation */
  .colon {
    animation: infiniteEaseLoop 1000ms cubic-bezier(0.42, 0, 0.58, 1) infinite;
  }
  .header-subtext-link{
    display: flex;
    overflow: hidden;
    
  }

  .header-cta-wrap{
    display: flex;
    flex-direction: row;
    z-index: 9999;
    gap: 1rem;
  }
  .header-hero-cta{
    display: none;
    pointer-events: all;
    transition: 500ms ease all 1.2s;
  }
  .header-toggled .header-hero-cta{
    transition: 200ms ease all;
    transition-delay: 0s;
    pointer-events: none !important;
    opacity: 0;
  }
  .header-toggled .header-cta-wrap,
  .header-toggled .header-cta-wrap *{
    pointer-events: none !important;
  }
  .header-inner-content, .header-menu{
    margin-inline:0;
    transition: 500ms ease all !important;
  }


@media only screen and (max-width:830px){
    .header-menu{
        height:70vh;
        margin-top:120px;
        margin-bottom: 3em;
        justify-content: flex-end;
        flex-direction: column;
        width: 86%;
        margin-inline:4.2%;
    }
    .header-inner-content{
        width: 80vw;
        margin-inline: 10vw;
        padding-inline: 0;
     
     
        
    }
   .header-hamburger{
      filter: invert(0) !important;

   }
    

    .header-wrap{
        background-color: transparent;
        backdrop-filter: blur(0px);
    }
    .header-menu-link{
        font-size: var(--font-size-heading-mobile);
    }
    .header-menu-subtext{
        transform: translateX(0);
        flex-direction: column;
        gap:2em;
        max-width: 100%;
    }
    .header-menu-links{
        position: relative;
        margin-bottom: 3em;
        display: flex;
        flex-direction: column;
        gap:0.5em;

    }
    .header-menu-links > a{
        width:100%;
        font-size: 4em;
    }
    .header-menu > div{
        width:100%;
    }
    .header-menu-subtext-links{
        position: relative;
        width: 100%;
    }
    .empty-menu-link{
        display: none;
    }
    .menu-time-wrap{
        display:none;
    }
    .minimal-header *{
        cursor: default !important;
    }
    .header-toggled .header-logo{
        filter: invert(0);
    }
    .header-menu-subtext > div{

        width: 100%;
    }
    .header-subtext-link{
        width: 50% !important;
    }
    a.header-subtext-link{
        width: min-content !important;
    }
    .reference-peace-sticker{
        transform: rotate(12.2deg) scale(0.8);
    }
    .header-hover-desc{
        display: none;
    }
    .header-subtext-link{
        width: 100% !important;
    }
    .header-menu-link::after{
        display: none;
    }
}
@media only screen and (max-width:830px) and (max-height:715px) {
    .header-menu-link{
        font-size: 8vh !important;
    }
    .header-subtext-links{
        display: none;
    }
}

