.page-content{
    height: fit-content;
}
.contact-page{
    width:92vw;
    margin-inline: 4vw;

}
.contact-page .mask-image{
    transform: translateY(-100%);
    transform-origin: center center !important;
    object-position: center top;
}
.contact-page .mask-image:hover{
    transform: translateY(-95%);
}
.contact-heading-wrap .heading{
    margin-left:122px;
    font-size: 6em !important;
    line-height: 1.08em !important;
    font-weight: 600;
    padding-top: 13px !important;
}
.contact-subtitle{
    margin-top:80px;
    margin-bottom: 0;
}
.contact-max-title{
    transition: 400ms ease-out all 0.5s, font-size 0ms;
}
.scrolled .contact-max-title{
    transition: 200ms ease-out all;
    opacity: 0;
    transform: translateY(-10px);
}
.contact-top{
    padding-top:140px;
    margin-bottom: -100px;

    display: flex;
    justify-content: space-between;
}
.contact-heading-wrap{
    transform: translateY(-120px);
    width: 66%;
    pointer-events: all;

}
.contact-image-wrap{
    width: 32.5%;
    z-index: 0;
    cursor: none;
    pointer-events: all;
}
.contact-image-wrap:hover{
  
}
.contact-image-wrap > img{
    width: 100%;
    border-radius: 28px;
}
.contact-heading-wrap span{
    display: block;
}
.contact-heading-line-2{
    margin-left:122px;
}
.contact-heading-line-3{
    margin-top:120px;
}
.contact-image-wrap{
    overflow:hidden;
    clip: rect(top, right, bottom, left);
}
.contact-bottom--wrap, .bottom-contact-bottom--area{
    display: flex;
    justify-content: space-between;
    gap:1.3%;

    height:inherit;

}
.contact-bottom--wrap > div{
    width:17%;
    margin-top: 2em;
}
.contact-bottom--wrap > div:nth-child(2){
    width: 29%;
}
.contact-bottom--col-2, .contact-bottom--col-3, .contact-bottom--col-4{
    display: flex;
    flex-direction: column;
    transform:translateY(16px);
    position: relative;
}
.contact-bottom--col-2 a, .contact-bottom--col-3 a, .contact-bottom--col-4 a{
    cursor:pointer;
}
a{
    font-family: var(--body-family);
    text-transform: uppercase;
    font-weight:500;
    font-size: 20px;
    letter-spacing: -1.5px;
}
.contact-bottom--col-3 > div, .contact-bottom--col-4 > div{
    position: absolute;
    bottom: 0;
    transform: translateY(2em);

}

.contact-bottom--wrap > div > div{
    display: grid;
}
.contact-bottom--clock-wrap{
    transform: translateY(1em);
}
.mobile-site-tag{
    opacity: 0;
    pointer-events: none;
}
.contact-bottom--archive-div, .contact-bottom--title, .acknowledgement-of-country{
    font-family: var(--body-family);
    font-family: 'Inter', sans-serif !important;


    opacity: 50%;
    font-size: 12px;

}
.country-wrap{
    padding-top:32px;
}
.flag-wrap{
    display: flex;
    gap:1em;
}
.flag{
    opacity: 0.5;
    transition: 500ms ease all;
}
.flag:hover{
    opacity: 1;
    
}
.retro-mode-wrap{
   margin-top:32px; 
}
.retro-mode-wrap > a:after{
    background-image: url('/public/icons/question-mark.svg');
    width: 12px;
    margin-top:6px;
}
.retro-mode-wrap > a:hover:after{
    transform: translateY(-19px) !important;
}
.retro-mode-wrap{
    max-height: 20px !important;
    overflow: hidden;

}
.acknowledgement-of-country{
    padding-top:32px;
}
.contact-bottom--title{
    text-transform: uppercase;
    padding-bottom: 32px; 
}
.contact-bottom--wrap .contact-bottom--archive-div{
    position: relative !important;

    padding-top: 32px;

    padding-bottom: 32px; 
}
.contact-bottom--wrap>div> .contact-bottom--archive-div:first-child{
    padding-top:0px;

}
.bottom-contact-bottom--area{
    height:120px;
    
}
.bottom-contact-bottom--area .contact-bottom--col-1{
    display:flex;
    align-items:center;
}
.bottom-contact-bottom--area .contact-bottom--col-2{
    width: 17%;
}
.contact-image-wrap .mask-mouse-area{
    align-items: start;
    height: fit-content;
    padding-bottom: 5rem;
}
.contact-image-wrap .mask-image-wrap{
    transition: cubic-bezier(0.76, 0, 0.24, 1) all 700ms, height 0ms;
    margin-left: 0px;
    height: 32vw;
    max-height: calc(90vh - 180px);
    width: 100% !important;
}
.contact-subtitle .text-reveal-element{
    margin-block:0px;
}
.contact-image-wrap .mask-image-wrap::before{
    scale: 1.5;
}
.contact-image-wrap .mask-image-wrap:hover::before{
    scale: 1.5;
}
    
.contact-image-wrap .mask-image{
    width: 100% !important;
}
.contact-page .details, .contact-player-project-name{
    pointer-events: none !important;
}
.contact-player-project-name p{
    color: black;
    pointer-events: none !important;
}
.contact-player-project-name p::after{
    margin-top:6px !important;
    background-color: black;
    transform: translateY(0px) !important;
}

@media only screen and (max-width:900px){
    .contact-heading-wrap .heading{
        font-size: 11vw !important;
    }
}

@media only screen and (max-width:830px){
    .contact-page{
        width:84vw;
        margin-inline:8vw;
    }
    .contact-heading-wrap{
        padding-top: calc(4vw + 28px) !important;
        transform: translateY(-140px);
    }
    
    .contact-subtitle > div{
        width: 100% !important;
        font-size: 10.5vw !important;
    }
    .contact-page .contact-max-title{

        font-size: 10.3vw !important;
        transform: translateY(2px);
        margin-left: 13.4vw;
    }
    body:not(.scrolled):has(.contact-page) .header-inner-content {
        height: 10.3vw;
        padding-top: calc(4vw + 40px);
    }
    .contact-bottom--wrap{
        width: 86%;
        margin-inline:6.5%;


        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }
    
    .contact-bottom--col-1{ grid-area: 1 / 1 / 2 / 4; }
    .contact-bottom--col-2 { grid-area: 2 / 1 / 3 / 2; }
    .contact-bottom--col-3 { grid-area: 2 / 2 / 3 / 3; }
    .contact-bottom--col-4 { grid-area: 2 / 3 / 3 / 4; }

    .all-button, .back-to-top{
        display: none !important;
    }


    .contact-bottom--wrap > div{
        width:100% !important;
    }
    .mobile-site-tag{
        width: 100%;
        display: flex;
        justify-content: center;
        opacity: 1;
        pointer-events: all;
    }
    .mobile-site-tag > a{
        padding: 2em;
    }
    .contact-bottom--clock-wrap{
        width: 130px;
    }
    .contact-heading-wrap{
        display: flex;
        flex-direction: column;
        width: 78vw;
    }
    .contact-top{
        flex-direction: column;
        align-items: center;
        width: 84vw;
        margin-bottom: 0px;
    }
    .contact-image-wrap{
        width: 100%;
        margin-top: -60px;
    }
    .contact-image-wrap .mask-image{
        width: 84vw !important;
    }
    .contact-image-wrap .mask-image-wrap{
        height:84vw !important;
    }
}