.about-page-section-3{
    height: auto !important;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}
.about-last-cta-wrap-heading img{
    transform: translate(-32%, 0%) rotate(-5deg);
    position: absolute;
    top: 5%;
    height: 90%;
    left: 0%;
    filter: drop-shadow(2px 2px 0 white) drop-shadow(-1px -0px 0 white);
}
.about-last-cta-wrap-heading{
    font-size:64px;
    position: relative;
    width: fit-content;
    cursor: none !important;
}
.about-last-cta-wrap-heading *{
    cursor: none !important;
}
.about-last-cta-wrap{
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 100%;
}
.about-last-cta-top{
    align-self: self-start;
    display: flex;
}
.body.first{
    width: 31.2vw;
}
@media only screen and (max-width:830px){
    .about-last-cta-wrap{
        margin-block:0px;
        display: flex;
        flex-direction: column;
    }
    .about-last-cta-wrap-heading{
        font-size: 7.5vw;
        line-height: 11vw;
    }
    .about-last-cta-top > p:first-child{
        width: 85%;
    }
    .about-last-cta-top > p:last-child{
        flex-grow: 1;
        width: auto !important;
    }
}