.reference-peace-first-page{
    height:calc(100vh - 4vw);
    width:92vw;
    margin-inline: 4vw;

    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: end;
    margin-bottom: 4vw;
    padding-bottom: 8vw;
}
.reference-peace-page p{
    margin:0;
}
.reference-peace-title-wrap{
    width:84vw;
    margin-bottom: -1em;
 

display: flex;
flex-direction: column;
pointer-events: none;
    
}

.reference-peace-title-wrap > div{
    display: flex;
}
.reference-peace-title-wrap > p, .reference-peace-title-wrap > div > p{
    pointer-events: all;
    font-family: var(--title-family);
    cursor: none;
    text-transform: uppercase;
    font-size: 9vw;
    width: max-content;
}
.reference-peace-title-wrap .reference-peace-subtitle{
    display: block;
    font-size: 8.65vw;
    line-height: 1.1em;
}
.last-subtext{
    width: 28%;
}
.trail-image{
    transform: translateY(-15%) translateX(-10%);
    clip-path: inset(0 0 100% 0 round 20px);
    scale: 0.8;
    border-radius: 10px;
    position: fixed;
    width: 24vw;
    height:20vw;
    object-fit: cover;
    opacity: 0;
    cursor: none;
    transform-origin: top left;
    display: block;
    pointer-events: none;
}
.reference-peace-title-reference{
    padding-right: 8.5vw !important;

}
.reference-peace-title-peace, .reference-peace-title-reference{
width: min-content;
position: relative;
pointer-events: all;
font-family: var(--title-family);
cursor: none;
text-transform: uppercase;
font-size: 9vw;
line-height: 1.1em;
width: max-content;
}
.reference-peace-title-peace > p, .reference-peace-title-reference > p{
    padding-top: 1em;
    z-index: 101;
}
.main-video-controls-overlay .scroll-notification{
    margin-bottom:0 !important;
}
.reference-peace-subtext .scroll-notification{
    color:black;
    opacity: 1 !important;
    position: relative !important;
    margin:0 !important;
    pointer-events: all;

    
}
.reference-peace-subtext .scroll-notification > p > p{
    animation: scrollText 3s infinite;
    animation-timing-function: cubic-bezier(0.76, 0, 0.24, 1);
    overflow: visible;
}
.reference-peace-subtext .scroll-notification > p > p:after{
    content:'scroll';
    position: absolute;
    display: block;
    margin-top: 1em;
}
.reference-peace-subtext{
    height: 9.4vw;
    display: flex;
    flex-direction: column;
    justify-content: end;
    line-height: 1em;
}
.reference-peace-subtext > div{
    margin:0 !important;
   
}
.reference-peace-subtext > div> p{
    padding: 0 !important;
}

.subtext-description-wrap > p > p{
    transform-origin: top center;
    font-size: 2em !important;
    line-height: 0.25em;
    transition: 200ms ease all 200ms;
    width: min-content !important;
}
.reference-peace-projects-wrap{
    margin-top:-8vw;
    width: 92%;
    padding-inline: 4%;
    min-height: 100vh;
    background-color: white;

    position: relative;
    box-shadow: rgb(255 255 255 / 57%) 0px 2px 29px 0px;
}
.subtext-description-wrap{
    display: flex;
    gap:9vw;
    padding-top: 3em;
    padding-bottom: 6em;
    position: relative;

}
.subtext-description-wrap p:first-child{
    width: 45.4vw;
}
.view-project-link h2{
    font-size:11.4vw !important;
    line-height: 1.1em !important;
    margin:0;
    cursor: none;
}
.projects-listing{
    display: flex;
    gap:8.6%
}
.projects-listing > .pin-spacer{
    pointer-events: all !important;
}
.projects-image-wrap{
   
  

    width:22vw;
    height: 22vw !important;
    margin-bottom: 600px !important;
    pointer-events: all !important;


    aspect-ratio:16/9;
    border-radius:10px;
    overflow: hidden;
    clip-path: inset(0 0 0 0 round 10px);
    transition: 200ms ease-out clip-path;

}
.subtext-description-wrap:last-child{
    width: 30%;
}
.projects-image{
    width: 22vw;
    object-fit: cover;
    aspect-ratio: 1;
    border-radius:10px;
    margin-bottom: 20px;
}
.scroll-notification{
    opacity: 1 !important;
}
.scroll-notification > p > div > span:after{
    content:'scroll';
    position: absolute;
    bottom:-100%;
    color:white;
    opacity: 1 !important;
}
.scroll-notification > p >  div > span{
    overflow: visible;
    animation: scrollText 3s infinite;
    animation-timing-function: cubic-bezier(0.76, 0, 0.24, 1);

}
@keyframes scrollText {
  from   { transform: translateY(0%); }
  to { transform: translateY(-99%); }
}


.reference-peace-single{
    min-height: 100vh;
    width: 92vw;
    padding-inline: 4vw;
    background-color: var(--secondary-dark);
}

.rp-title, .rp-description{
    margin:0;
    color:white !important;
   
}
.rp-title-wrap{
    padding-top:120px;
    padding-bottom: 2vw;
    display: flex;
    align-items: end;
    justify-content: space-between;
}
.rp-title{
    margin-bottom: -0.1em !important;
}
.parallax-image-wrap{
    overflow: hidden;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    width: 22vw;
    object-fit: cover;
    aspect-ratio: 1;
    border-radius:10px;
    transition: all 500ms ease;
    position: relative;
   
}

.parallax-button{
    position: absolute;
    pointer-events: none;
    bottom:24px;
    left: 28px;
    overflow: hidden;
}
.parallax-button p{
    transition: 500ms ease all;
    color:white;
    transform: translateY(100%);
}
.parallax-image-wrap:hover .parallax-button p{
    transform: translateY(0%);
}
.parallax-button p::after{
    background-color: white;
}
.parallax-image-wrap img{
    object-fit: cover;
}
.masonry-section *{
    cursor: none !important;
}
.masonry-image{
    cursor: none;
    width:100% !important;
    scale:1.03;
    transition: all 0.2s ease;
    object-fit: center center;
}
.masonry-image:hover{
    scale:1.02;
}
.masonry-section>div{
    margin-top:1.4vw !important;
}
.masonry-section{

    cursor: none !important;
}
.pdf-page-numbers{
    color: white;
    width:49%;
}
.pdf-page-switchers{
    display: flex;
    min-width: 22vw;
    justify-content: space-between;
}
.pdf-page-switchers > button{
    cursor: pointer;
    aspect-ratio: 1;
    width: 6vw;
    background-color: transparent;
    border: none;
    color: transparent;
    background-image: url(/public/Arrow/White-Arrow-Large.svg);
    background-size:cover;
    background-repeat: no-repeat;
}
.pdf-page-next{
    transform: rotate(90deg);
}
.parallax-image-wrap:hover{
    transform: scale(0.99);
}
div:has(> .rp-title){
 
    min-width: 50%;
}
div:has(> .rp-description){
    width: 36% !important;
    padding-right: 7%;
}
.main-section-video-wrap{
    position: relative;
    aspect-ratio: 16 / 9;
    display: flex;
    pointer-events: none;
    justify-content: end;
    align-items: end;

    width:100%;
    margin-top:1.4vw;

    overflow: hidden;
}
.pdf-section{
    margin-top:1.4vw;
}
.main-section-image{
    pointer-events: all;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    aspect-ratio: 16 / 9;
    position: relative;
}
.main-description-right-wrap{
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: 1000ms ease all !important;
    transition-delay: 150ms !important;
    
}
.main-description-right-wrap *{
    color:white;
}
.main-video-controls-overlay{
    position: absolute;
    display: flex;
    width: 100%;
    height: calc(100% - 24px);
    top:0;
    z-index: 100;
    display: flex;
    align-items: end;
    pointer-events: none !important;
}
.main-description-time-wrap{
   
    color: white;
    margin-left: 64px;
    width: calc(34% - 64px);
    transition: 500ms ease all 0ms;
}
.play-text{
    animation: none !important;
    display: flex;
    flex-direction: column;
}
.play-text p{
    transition: 500ms ease all;
    min-height: 20px;
}
.play-text-toggle p{
    transform: translateY(-20px);
 
}
.play-text::after{
    content: 'pause';
    position: absolute;
    transform: translateY(100%);

}
.main-video-controls-overlay > div{
    pointer-events: all;
}

.main-section-image-overlay{
    width: 100%;
    opacity: 1;
    height: 100%;
    background-image: url(/public/imagery/Films/Domengo\ Hero\ Image.png);
    background-size: cover;
    position: absolute;
    background-position: center;
    z-index: 10;
    pointer-events: none;
    transition: all 500ms ease;
}
.main-section-image:has(.playing) .main-section-image-overlay{
    opacity: 0;
}
.video_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    
 }
.player__wrapper{
    width: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.player {
    object-fit: cover;
    padding: 0;
    cursor: none;
    margin: 0;
    width: 50%;
    height: calc(100%);
    scale: 2;
    display: flex;
    justify-content: center;

}
 .player div:not(.scroll-notification){
    pointer-events: none;

    width: 100%;
    aspect-ratio: 9 / 16;
 }            
 .progress-bar{
    display: flex;
    align-items: end;
    position:absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 64px;
    margin-right: 70px;

    transition: 500ms ease all;
    transition-delay: 200ms;
    width: 260px;
 }
 .progress{
    background-color: white;
    height: 3px !important;
    border-radius: 1.5px;
    overflow: hidden;
 }
 



 @media only screen and (max-width: 830px) {
.reference-peace-subtitle{
   font-family: var(--title-family);
   text-transform: uppercase;
}
.reference-peace-title-wrap div{
   font-size: 4rem !important;
}
.reference-peace-title{
   flex-direction: column;
}
.reference-peace-title-wrap, .reference-peace-first-page{
    flex-direction: column;
    justify-content: end;
    align-items: start;
    transform: translateY(0px) !important;
}
.reference-peace-title-wrap{
    margin-bottom:0 !important;
 
}
.subtext-description-wrap{
    flex-direction: column;
}
.subtext-description-wrap p{
    width: 100% !important;
}
.scroll-notification p{
    justify-content: start !important;
}
.reference-peace-first-page{
    width: 84vw;
    margin-inline: 8vw;
    height: calc(100vh - 8vw);
    margin-bottom: 8vw;
}
.reference-peace-projects-wrap{
    width: 84vw;
    padding-inline:8vw;
}
.view-project-link > div{
    font-size: 10.5vw !important;
}
.mobile-RP-sticker{
    width: 41vw;
    max-height: 154px;
    max-width: 204px;
    border-radius: 10px;
    margin-bottom: 4vw;
}
.projects-image-wrap{
    clip-path: none !important;
    height: fit-content !important;
    border-radius: 0px !important;
}
 }