.login{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;


}

.login form{
    display: flex;
    flex-direction: column;
    align-items: center;


}
.login input{
    width:200px;
    height:30px;
    margin:10px;
}

.login button{
    width:200px;
    height:30px;
    border:none;
    background-color: black;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.login span{
    font-size: 12px;
    color:red;
    margin-top:10px;
}