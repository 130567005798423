.hamburger-button{
    cursor: pointer !important;

    transition: all 500ms ease;
    overflow: visible;

    margin-left:2.1vw;

}

.hamburger-button > path{

    transition: all 500ms ease-out !important;
    transform-origin: center right;
    transition-delay: 100ms;
    stroke: var(--primary-color) !important;
    transform: scaleX(1);
}


.active.hamburger-button *{

    transition-delay: 100ms;
}

.hamburger-button:hover{

    transform-origin: right center;
}
.hamburger-button.active .line-1, .hamburger-button.active .line-3  {
    transition: all 500ms ease-out !important;
    transform: scaleX(0);
}