section{
    background-color: var(--secondary-color);
  


}


.page{
    min-height:calc(300vh + 140px) !important;

}
.page-one, .page-two, .page-three, .page-four, .page-five{
    min-height: 100vh;
    padding-bottom: 0;
}

.page-three > .body{
    z-index: 1001 !important;
    position: relative;
}
.scrolled .area-1 .mask-image{
    opacity: 0 !important;
}
.scroll-video{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    transform: scale(1.05);
}

.dynamic-video-player{

    background-color: white;
    width:96vw;
    margin-right:4%;
    height:100vh;
    display: flex;
    align-items: end;

 
    pointer-events: none;
}
.see-my-work .body{
    padding-bottom: 2em !important;
}
.mask-image-blurhash-wrap{
    height: 100%;
    cursor: none;
    position: absolute;
    top:0;
    pointer-events: none;
    background-position: center center;
    transform-origin: center;
    background-size: cover;
    scale:1.1;
    opacity: 0;

}
.scrolled .area-1 .mask-image-blurhash-wrap{
    width: 45.3vw;
   opacity: 1;
  
}
.mask-image-blurhash-wrap > div{
    pointer-events: none !important;
}
.scrolled .area-1 .mask-image-blurhash-wrap > div{
    height: 100% !important;
    width: 100% !important;
    background-position: center center;
    transform-origin: center;
    background-size: cover;
    opacity: 0;

}
.scrolled .area-1 .mask-image-hover-opacity-visible{

    opacity: 1 !important;
}
.mask-image{
    width:calc(68.5vw);

    max-width: calc(100vw - 60px);
    height:calc(100vh - 4vw - 160px);
    object-fit: cover;
    overflow: hidden;
    transform: scale(1.05);
    cursor: none;
    position: relative;
    pointer-events: all;
    object-position: center center;
    transform-origin: center;
    aspect-ratio: 1;
    
}
.mask-image-wrap > div:first-child{
    transition: 1400ms cubic-bezier(0.22, 0, 0.24, 1) transform 200ms;
    height: 100%;
    transform: scale(1);

}
.scroll-video, .mask-image{
    transition: object-position 0.5s ease 0s, min-width 0.5s ease-out 0s, scale 0.3s ease-out;
}
.dynamic-video-player:has(.mask-image:hover) .scroll-video,
.mask-image:hover, .scroll-video:hover{
    scale: 1.05 !important;
}

.mask-image-wrap{
    border-radius: 20px;
    overflow: hidden;
    margin-left:-5px;
    position: relative;
    height:calc(95vh - 180px);
    max-height: 100%;
    clip-path: inset(0 0 0 0 round 20px);
    transition: transform 0.5s ease 0s, clip-path 1400ms cubic-bezier(0.60, 0, 0.24, 1) 200ms;
}
.home-project-list > .title{
    position: relative;
    
}
.home-project-list .text-reveal-element{
    padding-bottom: 1.2rem !important;

}
.prev-hover .project-color, .hovered-fast-project-wrap .project-color{
    clip-path: polygon(0 10%, 0 3%, 5% 0, 85% 0%, 100% 9%, 100% 79%, 95% 86%, 100% 94%, 96% 100%, 15% 100%, 4% 98%, 0% 85%);    height: 1vw;
    background-color: var(--primary-color);
    transition: 200ms ease-in-out all 100ms;
}
.project-color{
    content:'';
    aspect-ratio: 1;
    height:0.5vw;
    position: absolute;
    cursor: auto;
    top: 0.018rem;
    left: 0;
    transform:translateX(-0.2em);
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    transition: 200ms ease-in-out all 0ms;
    border-radius: 2px;

}
.mask-image-wrap:hover{
    transform: scale(0.992);
}
.mask-mouse-area > a{
    transform-origin: bottom right;
    max-height: calc(100vh - 4vw - 80px);
    min-height: 0px;
}
.mask-image-wrap::before{

    position: absolute;
    transition: all ease 1000ms;
    top:0;
    left:0;
    background: rgb(2,0,36);
    background: radial-gradient(circle, rgba(2,0,36,0) 33%, rgba(0,0,0,1) 100%, rgba(172,50,50,0) 100%);
    filter: blur(2px);
    opacity: 0.3;
    width: 100%;
    height: 100%;
    z-index: 1;

  
}
.mask-image-wrap:hover::before{
    opacity: 0.5;
    transform: scale(1.05);

}

.page-three .pin-spacer{
    padding: 0 !important;
}
.mask-mouse-area{
    width: 100%;
    height: calc(100vh - 4vw - 80px);
    min-height: 0px;
    margin-bottom: 4vw;
    align-items: bottom;
    display: flex;
    justify-content: flex-end;
    align-items: end;
    pointer-events: none;
    overflow: hidden;
  
  
}
.see-my-work .body{
    max-width: 16vw;
}
.text-content-layer{
    position: absolute;
    top: 0;

    width:100%;
    background-color: transparent;
    pointer-events: none;
}
.page-one{
    width: 92vw;
    min-height: 90vh;
    width: 92vw;
    margin-inline:4vw;
    height: calc(100vh - 4vw);
    background-color: transparent;
    position: relative;
}
.page-one *{
    pointer-events: all;
}
.see-my-work{
    pointer-events: all;
    position: absolute;
    bottom:0vh;

    transition: ease-in-out 400ms all;
    transition-delay: 500ms;

}
.scrolled .see-my-work{
    transition: ease 200ms all !important;
    transform: translateY(10px);
    pointer-events: none;
    opacity: 0;
}

.details{
    font-family: var(--body-family);
    text-transform: uppercase;
    letter-spacing: -1.5px;
    color: white;
    font-size: 20px;
    line-height: 0px;
    position: absolute;
    bottom: 0;
    z-index: 1;
    margin-left: 64px;
    margin-bottom: 56px;
    pointer-events: none;
    opacity: 0;
    transition: all 500ms ease;
    pointer-events: all;
    cursor: none;
    user-select: none;
}

.mask-image-wrap:hover .details{
    opacity: 1 !important;
}

.mask-image-wrap:hover .scroll-notification{
    opacity: 1;

    transition: all 1000ms ease;
    transition-delay: 1000ms;
}
.scroll-notification p{
    max-height: 20px;
    overflow: hidden;
    display: flex;
}
.mask-image-wrap:hover .scroll-notification p span{
    animation: scrollText 3s infinite;
    animation-timing-function: cubic-bezier(0.76, 0, 0.24, 1);
    animation-delay:2500ms;
}
.scroll-notification p span{
    animation: scrollText 2s infinite;
    animation-delay:10000ms;
    animation-timing-function: cubic-bezier(0.76, 0, 0.24, 1);
}
.scroll-notification p span{
    position: relative;
    margin-inline: 2px;


}
.scroll-notification p span:after{
    content:'scroll';
    position: absolute;
    display: block;
    transform: translateX(0px);
}
.scroll-notification p{
    margin: 0;
}
@keyframes scrollText {
    from   { transform: translateY(0%); }
    to { transform: translateY(-99%); }
  }
.scroll-notification{
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    margin-right: 64px;
    margin-bottom: 56px;
    color: white;
    opacity: 1;
    transition: all 1000ms ease;
    transition-delay: 4300ms;
    pointer-events: all;
    cursor: none;
}
body:has(.loading-wrap.is-loading) .home .scroll-notification{
    opacity: 0;
}
.details-button{
    position: relative;
    padding-right: 24px;
}
.details-button::after{
    content:'';
    mask-image: url(/public/Arrow/Black-Arrow.svg);
    mask-position: top center;
    mask-size: contain;
    margin-top: 0.18rem;
    margin-bottom:-16px;
    right: 0;
    scale:1.1;
    width:13.9px;
    height:40px;
    position: absolute;
    filter: invert(1);
    transition: 0ms !important;
    background-color: black;

}
body:has(.home .page-two.visible) .dynamic-video-player-1 .mask-image-wrap .details{
    opacity: 1;  
}
.scrolled .scroll-notification{
    transition: all 1000ms ease !important;
    transition-delay: 250ms !important;
    opacity: 0 !important;
    pointer-events: none;
}
.artist-name{
    display: inline;

}
  .header-toggled .header-hero-cta{
    opacity: 0;
  }
.comma{
    display: inline;
}
.scrolled .home .page-one .primary-button::after, .scrolled:has(.home) .header-hero-cta::after{
    transition-delay: 0ms !important;
    transform: translateY(100%) !important;
}
.max-title{

    transform: translateX(122px) translateY(0px);
    transition: ease 500ms all 0.5s, font-size 500ms ease 0s;
}
.scrolled .max-title{
    transition: ease 200ms all, font-size ease 0ms;
    transition-delay: 0ms;
    transform: translateX(122px) translateY(-10px);
    pointer-events: none;
    opacity: 0;

}
.main-heading-wrap{
    height:160px;
    display: flex;
    align-items: center;
    pointer-events: none;
}
.max-subtitle{
    padding-top: 24px;
    pointer-events: none;
    font-family: var(--body-family);
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: -1.5px;
    line-height:20px;
    margin: 0;
    position: fixed;
    pointer-events: all;
    transition: opacity 600ms ease 550ms, transform 400ms ease 0s;
}
.scrolled .max-subtitle{
    pointer-events: none;
    transition-delay: 0ms;
    transition: ease 200ms all 0s;
    transform: translateY(10px);
    transform-origin: left top;
    
    opacity: 0;

}
.page-two{

    height: 100vh;
    width: calc(92vw / 2);
    margin-inline:4vw;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 15rem;
    padding-bottom: 20rem;

}

.page-two .body{
    display: block;
}
.page-two > div{
    pointer-events: all;
}
.page-two-row-one{
    margin-bottom:40px;
}
.page-two-row-two{
    margin-bottom:20px;
}
.page-two-row-two > .body{
    line-height: 30px;
}
.page-two-row-three{
    width: 85%;
    margin-bottom:20px;

}
.page-two-row-three > .body{
    width: 242px;
    display: flex;
    flex-direction: column;
    gap:2rem;
    
}
.max-location{
    font-size:110px;
}
.player-project-name span{
    transform: translateY(-250%);
  
}
.primary-button.auto-height{
    max-height:fit-content !important;
    line-height: 1.1em !important;
    color: black;
    transition: 500ms ease;
}
.primary-button.auto-height::after{
    transform: translateY(0px) !important;
    margin-top:6px;
    right: 26px !important;
    background-color: black;
}

.player-project-name p{
    height: fit-content;
    line-height: 16px;
    display: flex;
    margin:0;
    padding:1px;
    overflow: hidden;

}
.player-project-name p span{
    transform: translateY(200%);
    opacity: 0;
}
.mask-image-wrap .player-project-name p span{
    
    transition: 0ms 0.5s;
    transform: translateY(120%);

}
.mask-image-wrap:hover .player-project-name p span{
    transition: ease-in-out 1s all 0.2s, opacity 0.5s;
    opacity: 1;
    transform: translateY(0%);
    
}
body:has(.section-three-wrap:hover) .dynamic-video-player-1 .mask-image-wrap  .player-project-name p > span{

  transition:  0.4s all ease-out 0s, opacity 0.5s ease !important;
}


.home-project-list > div{
    pointer-events: all;
}
.page-three-wrap{
    width: 46.65vw;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    margin-inline: 4vw;
    overflow: hidden;
}
.page-three{

   
    overflow: hidden;
    height: auto;
    min-height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 15;
   
   
}
.page-three-wrap{
    background-color: transparent !important;
}
.page-three-wrap .high-z-index-layer{
    pointer-events: all;
}
.page-three-wrap .primary-button{
    margin-bottom: 20vh;
    margin-top:1rem !important;
   

}

.home-project-list{
    position: relative;
    display: flex;
    flex-direction: column;


}
.home-project-list > .text-reveal-container{
    pointer-events: all;
    transition: 500ms ease all;
    cursor: none;


}
.home-project-list > div, .home-project-list > div > .text-reveal-container{
    width: fit-content;
}
.home-project-list > div{
    transition: 500ms ease all;
}
.prev-hover{

    cursor: none;
    transform: translateX(0.2em);

}
.home-project-list a{
    cursor: none;
}
a{
    color: var(--primary-color);
    text-decoration: none;
}
.scroll-video{
    opacity: 0;
}
.scrolled .scroll-video{
    opacity: 1 !important;
}
.dynamic-video-player-5 .mask-mouse-area > a{
    height: inherit !important;
}
.home-project-list > .high-z-index-layer{
    pointer-events: all;
}
.text-reveal-container:has(.born-in){
    margin:0px;
    margin-bottom: 16px;
}
.text-reveal-container > .born-in{
    padding: 0 !important;
}
.page-two-row-one .text-reveal-element{
    width: min-content;
}
.section-title{
    padding:5px;
    margin:-5px;
}
.expanded-section-page-link{
    display: flex;
    overflow: hidden;
    transition-delay: 0ms !important;
}
.page-four{
    width:92vw;
    margin-inline: 4vw;
    margin-bottom: 4vw;
}
.page-four-top-wrap{
    display: flex;
    justify-content: space-between;
    width: 92vw;
}
.page-four-top-wrap > div{
    width: 24%;
}
.page-four-middle-wrap{
    margin-block: 15rem;
    position: relative;

}
.page-four-service{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.page-four-service .title{
    padding-bottom: 0.1rem;
}
.home-hover-desc, .fast-hover-desc{
    height:fit-content;
    min-height: 1em;
    
    
    transform-origin: top left;
    transition: transform 500ms ease, clip-path 500ms ease, max-height 500ms ease, max-width 500ms ease, padding 500ms ease, width 500ms ease !important;
    z-index: 20;
    scale:1;

    padding:12px 14px 12px 42px;

    pointer-events: none !important;

    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    border-radius: 4px;
    overflow: hidden;
}
.home-hover-svg-wrap, .fast-hover-svg-wrap{
    aspect-ratio: 1;
    overflow: hidden;
    position: absolute;
    left:16px;
    top:15px;
}
.home-hover-svg-wrap > svg, .fast-hover-svg-wrap > svg{
    
    transform: scaleX(-1);
}
.home-hover-svg-wrap > svg > path, .fast-hover-svg-wrap > svg > path{
    transform: translateY(100%);
    transition: 500ms ease;
}
body:has(.featured-project-link:hover) .home-hover-svg-wrap > svg > path, body:has(.infinite-film-title:hover) .fast-hover-svg-wrap > svg > path{
    transform: translateY(0%);
}
body:has(.featured-project-link:hover) .home-hover-desc, body:has(.infinite-film-title:hover) .fast-hover-desc{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}
body:not(:has(.featured-project-link:hover)) .home-hover-desc, body:not(:has(.infinite-film-title:hover)) .fast-hover-desc{
    transition: 0s 0.7s, opacity 0.6s 0.1s, clip-path 0.5s 0.1s !important;
    opacity: 0;
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);


}

.home-hover-desc > .link-desc, .fast-hover-desc > .link-desc{
    margin:0 !important;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    position: relative;
    display: flex;
    min-height: auto;
    transition: clip-path 500ms ease-out 0s, color 500ms ease 0ms, width 500ms ease !important;
    pointer-events: none !important;
}
.home-hover-desc::before, .fast-hover-desc::before{


    content: '';
    width: 100%;
    height: 100%;
    background-color: white;
    position: absolute;
    top:0;
    left:0;
    display: block;
    transition: 700ms ease-out all, clip-path 200ms ease-out !important;
}

.home-hover-desc > .link-desc > span, .fast-hover-desc > .link-desc > span{
    min-width: max-content;

    text-align: right;
  
    font-size: 1.30em;
    min-height: 1em;
    line-height: 1em;

    pointer-events: none !important;
    position: relative;
  
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    pointer-events: none;
    transition-delay: 0.2s;
   transform: translateY(100%);
   color: white;
   margin-top:0;

}

.featured-project-link{
inline-size: 43vw;
overflow-wrap: break-word;
hyphens: auto;
max-width: 100%;
   
}
.page-transitioning .home-hover-desc  .link-desc{
    opacity: 1 !important;
}
body:not(:has(.featured-project-link:hover)):not(.page-transitioning) .home-hover-desc  .link-desc > span{
    transition: 500ms ease all !important;
    transform: translateY(100%) !important;
    color: white !important;
}
body:has(.featured-project-link:hover) .home-hover-desc{
    transition: transform 500ms ease 0s, width 500ms ease 0s, max-height 500ms ease 0s, max-width 500ms ease 0s, padding 500ms ease 0.5s, clip-path 500ms ease 0s !important;
    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%) !important;
}
body:has(.featured-project-link:hover) .home-hover-desc > p  > span, body:has(.infinite-film-title:hover) .fast-hover-desc > p  > span{
    transition: clip-path 500ms ease-out 0s, filter 500ms ease 0ms !important;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%) !important;
}
.home-hover-desc p{
    margin:0 !important;
}
.page-four-service .text-reveal-container{
    transition: 500ms ease-out all;
    width: min-content !important;
    user-select: none;
}
.page-four-service .text-reveal-container:hover{
    transform: translateX(20px);
}
.my-work-cta .primary-button{
    width: 157px;

}
.page-four-service-image-wrap{
    position: absolute;
    width: 22vw;
    height: 11vw;
    clip-path: inset( 100% 0 0 0  round 20px);
    right:0;
    top:0;
    transition: ease-out clip-path 500ms;
    transition-delay: 0s;
}
.page-four-service-image{
    transition: 400ms ease-out all 0s;
    border-radius: 20px;
    margin-bottom: 20px;
    width: 22vw;
    height: 11vw;
    background-image: url(/public/animated-cormac.webp);
    background-size: cover;
}
.page-four-service-image:nth-child(2){
    background-image: url(/public/Franco.png);
}
.service-toggle-desc .page-four-service-image-wrap{
    transition-delay: 0.2s !important;
    clip-path: inset( 100% 0 0 0  round 20px);
}
.service-image-mobile{
    border-radius: 20px;
    margin-bottom: 2em;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    width: 88vw;
    margin-left: -2vw;
}

.page-four-service > .service-desc{


    width: 22vw;
    transition: 1000ms ease all;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition-delay: 0.2s;
    display: flex;
    align-items: end;


}

.service-desc p{
    margin:0;
}
.page-four-middle-wrap:has( .title:hover) .page-four-service-image-wrap{
    clip-path: inset(0 0 0 0 round 20px);
    transition-delay: 0.3s;
}
.page-four-service:has( .title:hover  ) > .service-desc{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}
.service-toggle-desc .page-four-service-image-wrap{
    transition-delay: 0s;
    clip-path: inset( 100% 0 0 0 round 20px) !important;
}
.service-toggle-image .service-desc{
    transition-delay: 0s;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0) !important;
}
.home-contact-wrap{
    width: 92vw;
    margin-inline: 4vw;
    margin-bottom:2rem;
}
.service-img-wrap{
    width: 22vw;
    aspect-ratio: 2 / 1;
    max-height: 200%;
    display: flex;
    align-items: end;

}
.page-four-service .title{
    cursor:none;
    width: max-content;
}
.page-four-service:has(.title:hover) .service-desc img{
    transition: 800ms ease-out all 0.1s;
    transform: scale(1);
    max-height: 200%;
    

}
.page-four-service:has(.title:hover) .service-desc p{
    opacity: 1;
    transition: 500ms ease all 0.4s;
}
body:has(.page-three.visible) .player-project-name .mask-image-wrap{
    transition: none !important;
}
.section-three-wrap{
    min-height: 100vh;
    height: fit-content;
}
.what-i-do-cta{
    float: right;
    margin-right: 7.6vw;
}
.page-five{
 
    width: 92vw;
    
    margin-inline: 4vw;
  
    transform: translateY(calc(-200vh + 8vw));

}
.page-five > div::not(.dynamic-video-player){
    width: 49.2%;
}
.page-five-left{
    border-radius: 24px;
    overflow: hidden;
    margin-bottom: 4vw;
}
.page-five .page-five-container{
        display:flex;
    justify-content: space-between;  
    min-height:calc(100vh - 4vw - 120px);
    height:calc(100vh - 4vw - 32px);
}
.page-five .page-five-right{
   
    transition: 800ms ease all 500ms, clip-path 0ms, opacity 400ms ease 700ms;
    width: 40.8%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap:2rem;
    opacity: 1;
    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
}
.home:has(.page-six.visible) .page-five-right{
    opacity: 0;
    transform: translateY(-20px);
    pointer-events: none;
}
.home:has(.page-six.visible) .dynamic-video-player-5 .details .primary-button{
    opacity: 0;
}
.home:has(.page-six.visible) .mask-image-wrap::before{
    transform: scale(1.4);
}
.page-five-left > img{
    width: 100%;
    height:calc(100vh - 10vw);
    object-fit: cover;
    cursor: none;
}
.home-about-desc{
    display: flex;
    gap: 1.4vw;
}
.home-about-desc .body{
    width: 78%;
    margin-right: 4%;
}
.page-five-right{
    pointer-events: all;
}
.about-reference-wrap{
    position: relative;
    height:350vh;
    pointer-events: none;
}
.dynamic-video-player-4 .mask-mouse-area{
    padding-block: 5px;
    margin: 0 !important;
    justify-content: start;
}
.dynamic-video-player-5 .pin-spacer, .about-reference-wrap .pin-spacer{
    padding:0 !important;
    height: 100vh !important;
}
.dynamic-video-player-4{
    height: fit-content;
    justify-content: start;
    margin-top:4vw;
}
.dynamic-video-player-4 .mask-image{
    max-width: 45.4vw;
    width: 45.4vw;
}

.page-four-dynamic-wrap{
    margin-top: 15em;
 
    z-index: 0;
    position: relative;
}

.dynamic-video-player-4 .mask-mouse-area, .dynamic-video-player-5 .mask-mouse-area{
    overflow: visible !important;
}

.dynamic-video-player-4 .mask-image-wrap{
    margin-left: 0px;
    max-width: 45.4vw;
    height: calc(100vh - 8vw);
}
.about-reference-wrap .dynamic-video-player-5{
    height:calc(100vh - 4vw - 84px);
    position: absolute;

}
.dynamic-video-player-5{
    width: 100%;
    background-color: transparent;

}
.dynamic-video-player-5 .details{
    z-index: 9999;


}
.dynamic-video-player-5 .details .primary-button{
    line-height: 30px;
}
.primary-button.insta-button::after, .primary-button.insta-button::before{
    background-image: url(/public/icons/instagram.svg);

    background-repeat: no-repeat;
}
.primary-button.button-gradient.insta-button::after{
    margin-top:2px;
  
}
.primary-button.button-gradient.insta-button:hover::before{
transform:translateY(-30px)
}
.dynamic-video-player-5 .mask-mouse-area{
    justify-content: start ;
    align-items: start;
    margin-bottom: 0;
    margin-left: 4vw;
    height: 100%;
}
.dynamic-video-player-5 .mask-image-wrap{
    margin-left: 0px;
    max-width: 45.4vw !important;
    width: 45.4vw !important;
    min-width: 45.4vw;
    height:100%;
    
}

.page-six{
    height: fit-content;

    width: 79vw;
    margin-inline: 10.5vw;
    z-index: 200;
    position: absolute;
    bottom: 0;
    left: 0;
    cursor: none;
}
.dynamic-video-player-5 .mask-image{
    max-width: 45.4vw !important;
    width: 45.4vw !important;
    min-width: 45.4vw;

}
.home-reference-top{
    padding-top:30vh;
}

.home-reference-row{
    margin-bottom: 10rem;
}
.home-reference-top .body{
    margin-bottom: 3rem;
}
.home-reference-row{
    color:white;
}
.home-reference-bottom{
    display: flex;
    justify-content: space-between;
}
.home-reference-bottom-left{
    width: 59%;
}
.home-reference-bottom-right{
    width: 19.6%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.home-reference-row .primary-button{
    filter: invert(1) brightness(2);
    
}


.dynamic-video-player-5 .reference-peace-bg-gradient{
    background: rgb(0,0,0);
    mask-image: linear-gradient(0deg, rgba(0,0,0,1) 20%, rgba(2,0,36,0) 100%);
    height:200vh;
    width: 100%;
    position: absolute;
    top:0;
    z-index: 15;

}

.dynamic-video-player-5 .reference-peace-bg-gradient::before{
    content: url('/public/grain.webp');
    background-image: url('/public/grain.webp');
    object-fit: cover;
    height: 300%;
    width: 300%;
    display: block;
    pointer-events: none;
    opacity:0.1;
    animation: animateGrain 4s steps(5) infinite;
}
@keyframes animateGrain{
  0%, 100% { transform:translate(0, 0) }
  10%{
    transform:translate(-5%,-10%)
  }
  20%{
    transform:translate(-15%,-20%)
  }

  40%{
    transform:translate(-5%,-10%)
  }
  60%{
    transform:translate(-15%,-20%)
  }
  80%{
    transform:translate(-5%,-10%)
  }
  100%{
    transform:translate(-15%,-20%)
  }

}
.dynamic-video-player-5 .mask-image-wrap::before{
    transform: scale(3);
}
.page-four-bottom-wrap{
    margin-top:6rem;
    margin-left:31.1vw;
}
.page-seven{
    height:100vh;
}
.max-title{
    font-size: 6rem !important;
}
.see-my-work .body{
    transition: opacity 500ms ease;
}
.page-one, .page-two, .page-three, .page-four, .page-five{
  
}

.page-three .body{
    pointer-events: all;
}

.page-four-top-wrap p{
    margin-top: 0px ;
}
.page-four-top-wrap .body{
    max-width: 210px;
}

.details .primary-button{
    filter: invert(1);
    pointer-events: all;
    display: block;
    line-height: 24px;
    padding-top: 2px;
}
.details .primary-button::before{
    display: none;
}
.details .primary-button::after{

    right:12px;
    width: 14px;
}
.scroll-visible{
    opacity: 1;
}
.scroll-notification p span{
    animation: scrollText 3s infinite;
    animation-timing-function: cubic-bezier(0.76, 0, 0.24, 1);
}
.player-project-name > div > p{
    display: flex;
    flex-direction: column;
}
.featured-work-wrap{
    padding-top: 60px;
}

  
@keyframes spin {
    from {
        transform-origin: center center !important;
      transform: rotate(0deg);
    }
    to {
        transform-origin: center center !important;
      transform: rotate(100deg);
    }
  }
  


@media only screen and (max-width: 935px) {
    .header-hero-cta{
        opacity: 0;
        pointer-events: none;
    }
}

@media only screen and (max-width: 830px) {
    .home *{
        cursor: auto !important;
    }
    .dynamic-video-player-1 .mask-mouse-area, .dynamic-video-player-1 .mask-mouse-area *:not(.slider-vertical):not(.details):not(.details *):not(.scroll-notification):not(.scroll-notification *){
        min-height: calc(100px - 4vw);
        transition: min-height 1000ms ease 0s !important;
    }
    .see-my-work button{
        margin-bottom: 40px;
        color:black;
    }
    .page-two-row-one{
        width: 30%;
    }
    .page-five-container{
        position: sticky;
        top: 0;
    }
    .dynamic-video-player-1:has( .reduced-height) .text-content-mobile-scrollbar{
        opacity: 0;
    }
    .see-my-work button:after, .see-my-work button:before{
        background-color:black !important;
    }
    .see-my-work .body{
        max-width: none;
    }
    .header-hero-cta{
        opacity: 0;
    }
    .scrolled .reduced-height .mask-image-blurhash-wrap, .scrolled .reduced-height .scroll-video{
        filter: blur(20px) !important;
        scale: 1.1 !important;
    }
    .scrolled .reduced-height .details,  .scrolled .reduced-height .scroll-notification{
        opacity: 0 !important;
    }
    body:not(.scrolled) .dynamic-video-player-1 .mask-mouse-area > input{
        top:50vh;
        opacity: 0;
    }
    body:not(.scrolled) .slider-vertical{
        transition: 500ms ease opacity !important;
        opacity: 0;
    }
    .main-heading-wrap{
        padding-top: calc(4vw + 40px);
        height: 10.3vw;
    }
    .reference-peace-bg-gradient{
        background-color: black;
        border-radius: 20px;
        opacity: 0.3;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
    }
    .page-two-row-three .text-reveal-container{
        width: fit-content !important;
    }
    .dynamic-video-player-1 .mask-mouse-area{
     display: flex;
     flex-direction: column;
     align-items: center;

    } 
    .slider-vertical{
        position: absolute;
        top: calc(100px + 2vw);
        width: calc(100% - 100px - 6vw);
        max-width: calc(50vh - 10vw - 100px + 6vw);
        pointer-events: all;
        transform: rotate(90deg) translateX(50%);
        background: transparent;
        transition: 500ms ease opacity 1s !important;
        
    }
    .see-my-work .primary-button.button-gradient{
        background: none !important;
   }
    .page-one{
        position: relative;
    }
    .page-one, .page-two, .page-three, .page-four, .page-five, .home-contact-wrap{
        transition: 500ms ease all;
        width: 84vw !important;
        margin-inline: 8vw;
    }

    .page-one{
        filter: invert(1);
        width: 76% !important;
        margin-inline: 12%;
        position: absolute;
        z-index: 7000;
        top:0;
    }
    .scrolled  .page-one, .scrolled .page-one *{
        pointer-events: none !important;
    }
    .page-four-dynamic-wrap{
        z-index: 999;
        background-color: white;
    }
    .small-dynamic-video-player{
        align-items: start;
        padding-top: 0vw !important;
        width: 100vw !important;
        max-width: 100vw !important;
        z-index: 6000;
        background-color: transparent;
        height: fit-content !important;
        max-height: fit-content !important;
    }
    .page-five .page-five-right{
        transition: none !important;
    }
    .page-four-service-image-wrap{
        display: none;
    }
    .page-four-service .title{
        padding-bottom: 0.2em;
    }
    .page-four-service .text-reveal-container:hover{
        transform: translateX(0px);
        cursor: default;
    }
    .small-dynamic-video-player .mask-mouse-area{
        transition: padding 500ms ease;
        justify-content: center;
        padding-top: 6vw;
        padding-bottom:6vw;
        background-color: white;
    }
    .scrolled .small-dynamic-video-player .mask-mouse-area{
        padding-bottom: 6vw;
    }
    .page-four-dynamic-wrap .mask-image-wrap, .page-four-dynamic-wrap .mask-mouse-area{
        height: 84vw;
    }
    .dynamic-video-player-1 .mask-mouse-area{
        transform: translate(0) !important;
    }
    .page-five-right .primary-button{
        width: max-content !important;
    }
    .dynamic-video-player-5 .mask-image-wrap,  .page-four-dynamic-wrap .mask-image-wrap{
        width: 88vw !important;
        max-width: 88vw !important;
        transform: translateX(-2vw);
    }
    
    .page-four-dynamic-wrap .mask-mouse-area, .page-four-dynamic-wrap .mask-image-wrap, .page-four-dynamic-wrap .mask-image{
        width: 88vw;
        max-width: 88vw;
      
    }
    .small-dynamic-video-player .mask-image-wrap{
        width: 88vw !important;
        height: calc(100vh - 12vw) ;
    }
    .text-content-layer{
        margin-top: 100vh;
    }
    .dynamic-video-player-1 .mask-mouse-area{
        height: calc(100vh - 4vw);
    }
    .page-three-wrap{
        overflow: visible;
        clip-path: none;
    }
    .page-three{
        transform: translate(0,0) !important;
        margin-inline: 0 !important;
    }
    .featured-work-wrap{
        padding-bottom: 2em;
    }
    .page-two{
        padding-top:0 !important;
    }
    .area-1 .mask-image-blurhash-wrap{
        width: 100% !important;
    }
    .mask-mouse-area{
        align-items: start;
        height: fit-content !important;
  
        background-color: transparent !important;
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.89) 100%);
        backdrop-filter: blur(16px);
    }
    .home-hover-desc, .fast-hover-desc{
        display: none;
    }
    .home-project-list{
        gap:0.4em;
    }
    body:has(.home) .header-logo-link, body:has(.contact-page) .header-logo-link{
        transform: scale(1.4) translate(-2px,8.3px) rotate(-5deg);
    }
    .text-reveal-container{
        margin:0 !important;
        
    }
    .project-color{
        display: none;
    }
    .max-title{
      
        font-size: 10.3vw !important;
        transform: translateY(2px);
        margin-left: 12.6vw;
    }
    .page-three-wrap{
        width: 84vw;
        margin-inline: 8vw;
    }
    .page-two-row-three{
        flex-direction: column;
        gap: 40px;
    }
    .my-work-cta > div{
        width: fit-content !important;
    }
   .header-logo{
       width: 9vw !important;
     
   }
   .page-four-dynamic-wrap{
    margin-top: 0px;
    transform: translateY(875px);
    height: 0px;
   }
   .scrolled .header-logo{
        width: 45px;
   }
   .hamburger-button > path{
       stroke:black !important;
   }
  
    body:not(.scrolled):has(.home) .header-inner-content{
        filter: invert(1) !important;
    }
    .scrolled .max-title{
        transform: translateY(2px);
    }
    .max-subtitle{
        padding-top: 12px;
    }
    .scrolled .header-inner-content{
        height:100px !important;
    }
    .scroll-notification p{
        justify-content: end;
        max-height:fit-content;
        margin-top:5px;
        margin-bottom: 0;
    }
    .scrolled:has(.home) .details{
        opacity: 1;
    }
    .scroll-notification{
        margin-right:10%;
        margin-bottom: 40px;
        opacity: 1 !important;
    }
    body:not(.scrolled) .details{
        opacity: 0 !important;
    }
    .details{
        margin-left:6%;
        margin-bottom: calc(40px - 4vw);
        opacity: 1;
    }
    .player-project-name p span {

        transform: translateY(0%) !important;
    }
    .player-project-name p:last-of-type{
        margin-bottom:16px;
    }
    .mask-image, .scroll-video{
        width: 105% !important;
        max-width: 105%;
    }
    .text-content-mobile-scrollbar{
        position: absolute;
        width: 5px;
        bottom: 0;
        right: 6vw;
        transform: translateY(100%);
        height: calc(50vh - 12vw);
        background-color: lightgray;
        border-radius: 5px;
        opacity: 0;
        transition: 500ms ease opacity ;
    }
    .scrolled .text-content-mobile-scrollbar{
        transition: 500ms ease opacity 0.5s;
        opacity: 1;
    }
    .text-content-mobile-scrollbar-thumb{
        height:calc((50vh - 12vw) / 5);
        width: 100%;
        background-color: black;
        border-radius: 5px;
        position: absolute;
        bottom:80%;
        z-index: 9999;
    }
    .page-four-top-wrap{
        margin-block: 10rem;
    }
    .page-three-wrap .primary-button{
        margin-bottom: 4vw;
    }
    body:not(.scrolled) .dynamic-video-player-1 .mask-mouse-area,  body:not(.scrolled) .dynamic-video-player-1 .mask-mouse-area *:not(.details):not(.scroll-notification){
        min-height: calc(100vh - 12vw) !important;
    }
    .page-four-middle-wrap{
        margin-block: 5rem;

    }
    body:has(.home):not(.scrolled) .header-inner-content{
        width: 76vw;
        margin-inline: 12vw;
    }
    .home-reference-row{
        margin-bottom: 2rem;
    }
    .home-reference-row .heading{
        font-size: 11vw !important;
    }
    .home-reference-bottom-right{
        padding-block: 8vw;
        gap: 4vw;
    }
    .home-reference-bottom{
        flex-direction: column;
    }
    .home-reference-bottom > div{
        width: 100%;
    }
    body:has(.home) .header-inner-content{
        filter:invert(1);
    }
    .page-four-top-wrap{
        flex-direction: column;
        gap: 2rem;
        width: 100%;
    }
    .page-four-top-wrap-desc{
        display:flex;
        margin-block:5px;
        gap:15.5%;
 
    }
    .page-four-top-wrap > div{
        width: 100%;
    }
    .what-i-do-cta{

        float: left;
    }
    .page-four-service{
        margin-bottom: 5rem;
        flex-direction: column;
        max-height: none;
  
    }
    .text-reveal-container{
        margin-bottom: 0px !important;
    }
    .page-four-service .title{
        font-size: 11.2vw !important;
        width: 100% !important;
    }
    .page-four-service > .service-desc {
        transform: none !important;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%) !important;
    }
    .page-four-service > .service-desc img {
        width: 100% !important;
        transform: none !important;
        margin-block: 10px;
        max-height: none;
    }
    .page-four-service > .text-reveal-container{
        min-height:60px;
        height: fit-content;
    }
    .page-four-bottom-wrap{
        margin:0 !important;
    }
    .page-four-service .service-desc{
        width: 100%;
    }
    .page-four-service .service-img-wrap{
        width: 100%;
    }
    .page-four-service .service-desc p {
        opacity: 1 !important;
    }
    .home-about-desc{
        flex-direction: column;
        gap: 2em;
    }
    .home-about-desc > div{
        width: 54% !important;
    }
    .page-five{
        width: 100% !important;
        min-height: 875px;
        height: 875px;
        margin-top: 0;
        transform: translateY(0px);
    }
    .page-five-right{
        width: 100% !important;
        opacity: 1 !important;
    }
    .page-four{
        min-height: 0px;
    }
    
    .dynamic-video-player-5{
        position: relative;
        margin-top: 0px;

        padding-bottom: 8vw;
        height: fit-content !important;
        transform: translateY(calc(875px + 177vw));

    }
    .dynamic-video-player-5 .mask-image, .dynamic-video-player-5 .mask-image-wrap{
        min-width: 100% ;
        height: 100%;
    }
    .dynamic-video-player-5 a{
        width: 84vw;
        height: 84vw;
    }
    .about-reference-wrap{
        margin-top:0em;
        height: fit-content;
    }
    .page-six{
        padding-top: 360vw !important;
        position: relative;
    }
    .dynamic-video-player-5 .mask-mouse-area{
        margin-inline: 8vw;
        height: auto;
 
    }
    .page-six .home-reference-top{
        padding-top: 0;
    }
    .page-five .page-five-container{
        height: fit-content;
        max-height: fit-content;
    }
}


input[type=range] {

  -webkit-appearance: none;
 
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;

background: transparent;
    pointer-events: none;
  border-radius: 5px;
}
input[type=range]::-webkit-slider-thumb {
  height: 50px;
  max-width: 30%;
  width: 8px;
  pointer-events: all;
  z-index: 9999;
  border-radius: 5px;
    background: lightgray;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -11px;
}
