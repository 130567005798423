.footer{

    background-color: white;
    width:100%;
    min-height:240px;


    bottom: 0;
}

.footer-wrap, .bottom-footer-area{
    width:92vw;
    margin:auto;
    margin-inline: 4vw;
    display: flex;
    justify-content: space-between;
    gap:1.3%;

    height:inherit;

}
.footer-wrap > div{
    width:17%;
    margin-top: 2em;
}
.footer-wrap > div:nth-child(2){
    width: 29%;
}
.footer-wrap .footer-col-1{
    position: relative;
    padding-bottom: 80px;

}
.footer-col-2, .footer-col-3, .footer-col-4{
    display: flex;
    flex-direction: column;
    transform:translateY(16px);
    position: relative;
    padding-bottom: 3em;
}
.footer-col-2 a, .footer-col-3 a, .footer-col-4 a{
    cursor:pointer;
}
a{
    font-family: var(--body-family);
    text-transform: uppercase;
    font-weight:500;
    font-size: 20px;
    letter-spacing: -1.5px;
}

.footer-wrap > div > div{
    display: grid;
}
.footer-clock-wrap{
    transform: translateY(1em);
}
.mobile-site-tag{
    opacity: 0;
    pointer-events: none;
}
.footer-column a{
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 38px);
    width: calc(100% - 38px);
}
.footer-col-1 a{
    width: calc(100% - 38px);
}
.footer-archive-div, .footer-title, .acknowledgement-of-country, .acknowledgement-of-country > a{
    font-family: var(--body-family);
    font-family: 'Inter', sans-serif !important;
    text-transform: initial;
    letter-spacing: 0em;
    color: black;
    opacity: 50%;
    font-size: 12px;

}
.acknowledgement-of-country > a{
    opacity: 100%;
}
.find-me-wrap{

    position: absolute;
    bottom: 0;
}
.find-me-wrap p{
    margin:0;
}
.flag-wrap{
    display: flex;
    gap:1em;
}
.flag{
    opacity: 0.5;
    transition: 500ms ease all;
}
.flag:hover{
    opacity: 1;
    
}
.retro-mode-wrap{
   margin-top:32px; 
}
.retro-mode-wrap > a:after{
    background-image: url('/public/icons/question-mark.svg');
    width: 12px;
    margin-top:6px;
}
.retro-mode-wrap > a:hover:after{
    transform: translateY(-19px) !important;
}
.retro-mode-wrap{
    max-height: 20px !important;
    overflow: hidden;

}
.acknowledgement-of-country{
    padding-top:32px;
}
.footer-title{
    text-transform: uppercase;
    padding-bottom: 32px; 
}
.footer-wrap .footer-archive-div{
    position: relative !important;

    height: 28px;
    display: flex;
    align-items: center;
}
.footer-column-cta{
    position: absolute;
    bottom:0;
}
.footer-wrap>div> .footer-archive-div:first-child{
    padding-top:0px;

}
.bottom-footer-area{
    height:120px;
    
}
.bottom-footer-area .footer-col-1{
    display:flex;
    align-items:center;

}
.bottom-footer-area .footer-col-2{
    width: 17%;
}

@media only screen and (max-width:850px){
    .footer-wrap{
        width: 84%;
        margin-inline:8%;


        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }
    
    .footer-col-1{ grid-area: 1 / 1 / 2 / 4; }
    .footer-col-2 { grid-area: 2 / 1 / 3 / 2; }
    .footer-col-3 { grid-area: 2 / 2 / 3 / 3; }
    .footer-col-4 { grid-area: 2 / 3 / 3 / 4; }

    .all-button, .back-to-top{
        display: none !important;
    }

 

    .footer-wrap > div{
        width:100% !important;
    }
    .mobile-site-tag{
        width: 100%;
        display: flex;
        justify-content: center;
        opacity: 1;
        pointer-events: all;
    }
    .mobile-site-tag > a{
        padding: 2em;
    }
    .footer-clock-wrap{
        width: 130px;
    }
    .bottom-footer-area{
        width: 84%;
        margin-inline: 8vw;
    }
}